import React, { useEffect, useState } from 'react';
import moment from "moment";
import endPoints from "../../../services/api/root/endPoints";
import attachmentIcon from '../../../assets/images/paperclip.png';

import { Link } from 'react-router-dom';

export const NoticeBoard = () =>{

    const [notices, setNotices] = useState([])
    const [meetingRequests, setMeetingRequests] = useState([])
    const [meetingInvites, setMeetingInvites] = useState([])

useEffect(() => {
    endPoints.getNotices().then((res:any) => {
        if (res.success) {
            setNotices(res.message)
        }
    })
    endPoints.getMeetingRequests().then((res:any) => {
        if (res.success) {
            setMeetingRequests(res.message)
        }
    })
    endPoints.getMeetingRequestsInvites().then((res:any) => {
        if (res.success) {
            setMeetingInvites(res.message)
        }
    })
}, [])

  return(

    <div className = 'noticeBoard dashboardContainer'>
        <div className = 'one'>
            <div className = 'top'>
                <h1 className = 'primary line'>Notice Board</h1>
                <div className = 'right'>
                    <p className = 'dateYear primary'>{moment().format('YYYY')} <br/> <span className = 'dateMonth'>{moment().format('MMMM')}</span><span className = 'dateDay'>{moment().format('DD')}</span></p>
                </div>
            </div>

            <div className = 'board'>
                    {notices.length ? <div>
                        <p className = 'primary medium'>Admin Notices</p>
                        {notices.map((item:any, index) => {
                            return (<div className = 'boardItem' key={index}>
                                <p className = 'primary boardItemContent'>{item.notice}</p>
                                <p className = 'primary bold boardItemDate'>{moment(item.updated_at).format('MMMM Do YYYY, h:mm a')}{item && item.attachment ? <a href={`${process.env.REACT_APP_API_URL}${item.attachment.url}`} target={'_blank'}><img style={{width: 16, marginLeft: 4}} src={attachmentIcon}></img></a> : null}</p>
                                </div>)
                        })}
                    </div> : null}

                    {/* <div>
                        <p className = 'primary light'>My Meetings</p>
                        {(meetingRequests.length || meetingInvites.length) ? <div className = 'boardItem'>
                        {meetingRequests.map((item:any, index) => {
                            return (<p className = 'primary'>You invited <b>{item.member.name} {item.member.surname}</b> to join <a className = 'medium primary link' href={item.meeting.join_url} rel="noreferrer" target="_blank">{item.meeting.title}</a> <span className = 'bold'>| {moment(item.meeting.startTime).format('MMMM Do YYYY, h:mm a')} </span></p>)
                        })}
                        {meetingInvites.map((item:any, index) => {
                            return (<p className = 'primary'><b>{item.owner.name} {item.member.surname}</b> Invited you to join <a className = 'medium primary link' href={item.meeting.join_url} rel="noreferrer" target="_blank">{item.meeting.title}</a> <span className = 'bold'>| {moment(item.meeting.startTime).format('MMMM Do YYYY, h:mm a')} </span></p>)
                        })}
                        </div> : null }
                    </div> */}

                    {/*<div>
                        <p className = 'primary medium'>Member Notification</p>
                        <div className = 'boardItem'>
                            <p className = 'primary'>Member Meeting <span className = 'bold'>| 09:30am - 10:00am</span></p>
                        </div>
                    </div>*/}

                    {/*<div>
                        <div className = 'boardItem'>
                            <p className = 'primary'>Member Meeting <span className = 'bold'>| 09:30am - 10:00am</span></p>
                            <p className = 'primary'>Member Meeting <span className = 'bold'>| 09:30am - 10:00am</span></p>
                            <p className = 'primary'>Member Meeting <span className = 'bold'>| 09:30am - 10:00am</span></p>
                            <p className = 'primary'>Member Meeting <span className = 'bold'>| 09:30am - 10:00am</span></p>
                        </div>
                    </div>*/}
  </div>
        </div>
    </div>
  )
}