import React, { useState, useEffect } from 'react';
import BasicComponents from '../../basicComponents/index'
import { Button, TextField } from '@material-ui/core';
import endPoints from "../../../services/api/root/endPoints";
import ImageUploader from "react-images-upload";
import {globalLocalStorage} from "../../../services/storage/localStorage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Loader = require('react-loader');

export const SignUpStepThree = (Props: any) =>{

    // const [zoomaccount, setZoomaccount] = useState('');
    const [subscription, setSubscription] = useState(true);
    const [profileImage, setProfileImage] = useState<any>(null);
    const [payfast, setPayfast] = useState<any>(false);
    const [dynamicCard, setDynamicCard] = useState<any>(null);
    const [loaded, setLoaded] = useState<any>(true)

    const onDrop = (pic:any) => {
        if (pic) {
        setLoaded(false);
        endPoints.uploadProfilePicture(pic).then((res: any) => {
            if (res.success) {
                setProfileImage(res.message);
                toast.success("Your profile picture was updated successfully", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                  });
                //   window.location.href = '/home'
                endPoints.me().then((res: any) => {
                    if (res.success) {
                        endPoints.userById(res.message.id).then((result:any) => {
                            setLoaded(true);
                            if (result.success) {
                                setProfileImage(result.message.profileImage);
                            }
                        })
                    }
                })
            }
        })
    }
    };

useEffect(() => {

    const search = window.location.search;
    const paymentId = new URLSearchParams(search).get('id');
    const zoomCode = new URLSearchParams(search).get('code');

    endPoints.getPayLink().then((res:any) => {
        if(res.success) {
            setDynamicCard(res.message.htmlForm);
        }
    });

    endPoints.getPayfastInfo().then((res:any) => {
        if(res.success) {
            setPayfast(true);
        }
    });

    // if (paymentId) {
    //     endPoints.createTokenised(paymentId).then((res:any) => {
    //         if(res.success) {
    //             toast.success("You have successfully subscribed", {
    //                 position: "top-center",
    //                 autoClose: 3000,
    //                 hideProgressBar: true,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: false,
    //                 progress: undefined,
    //               });
    //               window.location.href = '/home'
    //         }else {
    //             toast.error("You have successfully subscribed", {
    //                 position: "top-center",
    //                 autoClose: 3000,
    //                 hideProgressBar: true,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: false,
    //                 progress: undefined,
    //               });
    //               window.location.href = '/home'
    //         }
    //     })
    // }

    // if (zoomCode) {
    //     endPoints.fetchandstoreaccesstoken({
    //       authorizationToken: zoomCode
    //     }).then((res:any) => {
    //       if (res.success) {
    //         setZoomaccount("added");
    //         toast.success("Successfully added your zoom account", {
    //             position: "top-center",
    //             autoClose: 3000,
    //             hideProgressBar: true,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: false,
    //             progress: undefined,
    //           });
    //           window.location.href = '/home'
    //     }else {
    //         toast.error("Unable to add your zoom account", {
    //             position: "top-center",
    //             autoClose: 3000,
    //             hideProgressBar: true,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: false,
    //             progress: undefined,
    //           });
    //     }
    //   })
    // }

    endPoints.me().then((res: any) => {
        if (res.success) {
            endPoints.userById(res.message.id).then((result:any) => {
                if (result.success) {
                    // setZoomaccount(result.message.zoomaccount);
                    setProfileImage(result.message.profileImage);
                }
            })
        }
    })

    // endPoints.prepareCheckout().then((res: any) => {
    //     if (res.success) {
    //         const script = document.createElement('script');
    //         script.src = res.message.checkoutUrl;
    //         script.async = true;
    //         document.body.appendChild(script);
    //     }
    // })

  }, [])

 const zoomAuthorize = async() => {
        endPoints.zoomauthorize({}).then((res:any) => {
            window.open(res.message,'_blank');
        })
}

  return(
    <div className = 'signUpModal'>
    <img className= 'logo' src={require('../../../assets/images/logo.png')}/>
    <h2 className = 'primary light tcenter'>Step 3</h2>
    <Loader loaded={loaded}>
        <div className = 'three signUpContent gcg30'>

            <div className = 'signIn one'>
                <h4 className = 'primary light'>Add Profile Picture</h4>
                {!profileImage? <ImageUploader
                    {...Props}
                    withIcon={false}
                    withPreview={true}
                    withLabel={false}
                    singleImage={true}
                    onChange={onDrop}
                    buttonText="Select Profile Picture"
                    imgExtension={[".jpg",".jpeg", ".gif", ".png", ".gif"]}
                    maxFileSize={5242880}
                /> : 
                    <div className="profileImageContainer">
                      <img className = 'profileImage' src = {`${process.env.REACT_APP_API_URL}${profileImage?.url}`}/>
                      <ImageUploader
                      withIcon={false}
                      withPreview={false}
                      withLabel={false}
                      singleImage={true}
                      onChange={onDrop}
                      className="uploadContainer"
                      buttonClassName="uploadButton"
                      buttonText="Select New Profile Picture"
                      imgExtension={[".jpg",".jpeg", ".gif", ".png", ".gif"]}
                      maxFileSize={5242880}
                  />
                    </div>}
            </div>
            <div className = 'verticalBreak'/>
            <div>
                <h4 className = 'primary light'>Payment Setup</h4>
                {!payfast ? <div><div className='paymentContent'>
                    <p>To subscribe to the features of the Virtual Chambers platform, please complete the payment setup by clicking on the Payment Setup button below.</p>
                    <br/>
                    <p>You will then be redirected to Payfast to set up your monthly debit order.</p>
                </div>
                <div className="content" dangerouslySetInnerHTML={{__html: dynamicCard}}></div>
                <img className="paymentImg" src='https://i2.wp.com/immortalart.co.za/wp-content/uploads/2019/08/new_payfast_logo_large.png'/>
                </div>
                :
                <div className='paymentContent'>
                 <p>You have already set up your Virtual Chambers debit order.</p>
                 <br/>
                 <p>You will be able to update your debit order information once you have completed the rest of your profile.</p>
                </div>
                }
            </div>
        </div>
        { (profileImage && payfast) && <div className="center-button">
        <BasicComponents.DefaultBtn
        label = 'continue'
        onClick = {()=>  window.location.href = '/home' }
      />
      </div>}
        </Loader>
  </div>
  )
}
