import React, { useState } from "react";
import BasicComponents from "../../basicComponents/index";
import "react-toastify/dist/ReactToastify.css";
import { useModalProvider } from "../../../context/modalProvider";

const Loader = require("react-loader");

type Props = {
  heading: string;
  content: string;
  url?: string;
};

export const ConfirmationModal = (Props: Props) => {
  const { toggleModal } = useModalProvider();

  const initConfirm = () => {
    toggleModal(false);
    window.open(Props.url, '_blank');
  };

  return (
    <div className="customModal">
      <span onClick={() => toggleModal(false)} className="close primary"></span>
      <p className="tcenter large primary bold">{Props.heading}</p>
      <p className="tcenter">{Props.content}</p>
      <BasicComponents.DefaultBtn
        onClick={initConfirm}
        label="Continue"
      />
    </div>
  );
};
