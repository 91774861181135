import React, { useState } from "react";
import BasicComponents from "../../basicComponents/index";
import { useAuthenticationProvider } from "../../../context/authenticationProvider";
import endPoints from "../../../services/api/root/endPoints";
import { globalLocalStorage } from "../../../services/storage/localStorage";
import "react-toastify/dist/ReactToastify.css";
import { useModalProvider } from "../../../context/modalProvider";

const Loader = require("react-loader");

type Props = {
  heading: string;
  content: string;
}

export const CustomModal = (Props: Props) => {
  
  const { toggleModal } = useModalProvider();

  return (
    <div className = "customModal">
      <span
          onClick={() => toggleModal(false)}
          className="close primary"
        ></span>
        {/* <img className="welcomeLogo" src={require("../../../assets/images/logo.png")}/> */}
        <p className="tcenter large primary">{Props.heading}</p>
        <p className="tcenter">{Props.content}</p>
        {/* <BasicComponents.DefaultBtn onClick={null} label="View Dashboard" /> */}
    </div>
  );
};
