import React, {useEffect, useState} from 'react';
import endPoints from "../../../services/api/root/endPoints";

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';
import {globalLocalStorage} from "../../../services/storage/localStorage";
import { Link } from 'react-router-dom';
import moment from "moment";

const Juniors = () =>{

  const [userGroups, setUserGroups] = useState([])
  const [originalData, setOriginalData] = useState([]);

  function getFirstLetterFrom(value:string) {
    return value.slice(0, 1).toUpperCase();
  }

  function searchLogic(source:any, name:any) {
    var results = [];
    var index;
    var entry;

    name = name.toUpperCase();
    for (index = 0; index < source.length; ++index) {
        entry = source[index];
        if (entry && entry.name && (entry.name.toUpperCase().indexOf(name) !== -1 || entry.surname.toUpperCase().indexOf(name) !== -1)) {
            results.push(entry);
        }
    }

    return results;
  }

  function groupData(data:any) {
    let grouped:any = data
        .reduce(function (list:any, user:any) {
        let listItem = list.find((item:any) => item.letter && item.letter === getFirstLetterFrom(user.surname));
        if (!listItem) {
          list.push({"letter": getFirstLetterFrom(user.surname), "users": [user]})
        } else {
          listItem.users.push(user)
        }
        return list;
        }, []);

        let sorted: any = grouped.sort((a:any, b:any) =>{
          if(a.letter < b.letter) { return -1; }
          if(a.letter > b.letter) { return 1; }
          return 0;
        })
        setUserGroups(sorted);
  }

  const search = (event:any) => {
    const query:any = event.target.value;
    if (!query.length)
      return groupData(originalData);
    groupData(searchLogic(originalData, query));
  };

  useEffect(() => {
    let user:any = globalLocalStorage.getItem("user");
    if (!user)
      /* window.location.href = '/home' */
      
    // else {
      endPoints.getUsers().then((res:any) => {
        if (res.success) {
          let filtered: any = res.message.filter((user:any) => {
            if (user.payfastpayments.length < 1 && (!user.influencer && !user.guest)) {
              return false;
            }
          return ((parseInt(moment().format('YYYY')) - parseInt(moment(user.advocateDateOfAdmission).format('YYYY'))) < 5);
          })
          filtered = filtered.filter((user:any) => {
            return user.profileImage != null;
          })
          setOriginalData(filtered);
          groupData(filtered);
        }
      }) 
    // }
  }, [])

  return (
    <div className = 'membersList'>
      <div className = 'top holder'>
        <div className = 'one'>
          <div className = 'flexRow'>
            <Link style={{textDecoration: 'none'}} to={'/members'}><h1 className = 'primary bold first'>Our Members</h1></Link>
            <h1 className = 'primary bold active' >Our Juniors</h1>
          </div>
        </div>
      </div>
          <div className = 'membersGrid'>
            <div className = 'holder noPadding'>
              <div className = 'inputGroup'><span className="innericon searchIconBlue"/><input placeholder = 'Search' className = 'defaultSearch' onChange={search} type='text'/></div>
              </div>
              {userGroups.map((group:any, index:number) => {
                return (
              [<div className = 'holder noPadding' key={group.letter}>
                <h2 className = 'primary'>{group.letter}</h2>
              </div>,
              <div className = 'six grg10' key={index}>
              {group.users.map((item:any, index:number) => {
                return (
                  <Link to={`/profile/${item.id}`}  key={index}>
                  <EnhancedComponents.MembersCard name = {item.name} surname={item.surname} number = {item.phone} email = {item.email} user={item}/>
                  </Link>
                  )
              })}
              </div>]
              )})}
          </div>
    </div>
  )
}

export default Juniors