import React, { useEffect, useState } from 'react';

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';
import endPoints from "../../../services/api/root/endPoints";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useModalProvider } from "../../../context/modalProvider";
import Modal from 'react-modal';

interface password {
    new_password: string,
    confirm_password: string,
  }

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
  },
};

const Loader = require('react-loader');

const ResetPassword = () =>{

    const [loaded, setLoaded] = useState<any>(true);
    const [error, setError] = useState(null);
    const {active, toggleModal} = useModalProvider();
    const [modalHeading, setModalHeading] = useState<string>('');
    const [modalContent, setModalContent] = useState<string>('');
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);
    const urlParams = new URLSearchParams(window.location.search);

    const phoneRegExp = /^\+?[0-9]\d{9,14}$/g;
  
    const handleSubmit = async (fields: password) => {
      const code = urlParams.get('code')
      setLoaded(false);
      const obj = {new_password: fields.new_password,
        confirm_passowrd: fields.confirm_password,
        code: code,};
      endPoints.resetPassowrd({
        password: fields.new_password,
        passwordConfirmation: fields.confirm_password,
        code: code,

      }).then((res:any) => {
        setLoaded(true);
        if (res.success) {
          setModalHeading('Thank You');
          setModalContent('Your password has been successfully changed.');
          toggleModal(true);
        }else {
          setModalHeading('Error');
          setModalContent('We were unable to change your password.');
          toggleModal(true);
        }
     });
    };


  return (
    <div>
      <Modal style={customStyles} isOpen={active}><EnhancedComponents.CustomModal heading= {modalHeading} content={modalContent}/></Modal>
        <div className = 'top holder contactUs'>
            <h1 className = 'line primary'>Reset Your Password</h1>
            <div className = 'one'>

                <Loader loaded={loaded}>
                 <Formik
                initialValues={{
                    new_password: '',
                    confirm_password: '',
                }}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    new_password: Yup.string()
                        .min(6, 'Password must be at least 6 characters')
                        .matches(
                          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                        )
                        .required('Password is required'),
                    confirm_password: Yup.string()
                        .oneOf([Yup.ref('new_password'), ''], 'Passwords must match')
                        .required('Confirm Password is required')
                })}
                onSubmit={handleSubmit}
                >
                  {(formik: any) => {
                    const {
                      values,
                      handleChange,
                      handleBlur,
                    } = formik;
                    return (
                        <Form>
                        <div className = 'one'>
                            <h4 className = 'secondary medium'>Enter your new password details below</h4>
                            <div className='passwordInput'>
                            {error && <div className="error">{error}</div>}
                              <input 
                                placeholder = 'New Password'
                                name = 'new_password'
                                type = {showPass ? 'text' : 'password'}
                                id= 'new_password'
                                value={values.new_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className = 'defaultInput'
                              />
                              <i className='fa fa-eye showPassword' onClick={() => setShowPass(!showPass)}/>
                            </div>

                            <ErrorMessage name="new_password">
                              {(msg) => <div className="error">{msg}</div>}
                            </ErrorMessage>
                            <div className='passwordInput'>
                              <input 
                                placeholder = 'Confirm Password'
                                name = 'confirm_password'
                                type = {showConfirmPass ? 'text' : 'password'}
                                id= 'confirm_password'
                                value={values.confirm_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className = 'defaultInput'
                              />
                              <i className='fa fa-eye showPassword' onClick={() => setShowConfirmPass(!showConfirmPass)}/>
                            </div>
                            <ErrorMessage name="confirm_password">
                              {(msg) => <div className="error">{msg}</div>}
                            </ErrorMessage>
                        </div>
                        <BasicComponents.SecondaryButton
                              label = 'Submit'
                              type="submit"
                              onClick = {null}
                            />
                      </Form>
                );
            }}
          </Formik> 
          </Loader>
            </div>
        </div>  
    </div>
  )
}

export default ResetPassword;