import React, { useEffect, useState } from 'react';
import moment from "moment";

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';
import endPoints from "../../../services/api/root/endPoints";
import {globalLocalStorage} from "../../../services/storage/localStorage";
import {loginCheckService} from "../../../services/checkLogin";

const Courtlinks = () =>{

    const [links, setLinks] = useState([])

    useEffect(() => {
      // loginCheckService.check();
        endPoints.getCourtLinks().then((res:any) => {
            if (res.success) {
                setLinks(res.message);
            }

       })
      }, [])


  return (
    <div>
      <div className = 'holder top'>
        <div className = 'courtLinks one'>
            <h1 className = 'primary line'>Court Links</h1>
            <div className = 'three currentLectures'>
                    {links.map((item:any, index) => {
                    return <div className="skillsListDropdown" key={item.id}>
                        <a target="_blank "href= {`${item.link}`}>
                        <div className = 'flexRow spaceBetween'>
                            <h3 className = 'primary bold'><a target="_blank " className = 'noLinkDeco primary' href= {`${item.link}`}>{item.name}</a></h3>
                            <img id = 'chevron' className =  'chevron' src = {require('../../../assets/images/chevronBlue.png')}/>
                        </div>
                        </a>
                    </div>})}
            </div>
        </div>
      </div>
    </div>
  )
}

export default Courtlinks