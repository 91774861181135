import * as React from "react";
type ModalType = {
  active: boolean;
  toggleModal: (active: boolean) => void;
};
export const ModalContext = React.createContext<ModalType>({
    active: true,
    toggleModal: () => null
});
export const useModalProvider = () => React.useContext(ModalContext);
const ModalProvider: React.FC<{}> = ({ children }) => {

  const [active, setActive] = React.useState<boolean>(false);

  function toggleModal(active: boolean) {
    setActive(active);
  }
  return (
    <ModalContext.Provider
      value={{
        active,
        toggleModal
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
export default ModalProvider;