import React from 'react';

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';

const AboutUs = () =>{
  return (
    <div>
      <div className="top holder aboutUs">
        <h1 className="line primary">What & Who Are Virtual Chambers</h1>
        <h3 className="primary">
          Virtual Chambers is an independent group of Advocates that are:
        </h3>

        <div className="aboutUsGridOuter one">
          <div className="aboutUsGrid two">
            <div className="aboutUsContent">
              <div className="aboutUsContentTop">
                <h1 className="primary">Compliant</h1>
                <img
                  className="aboutUsContentIcon"
                  src={require("../../../assets/images/doubleTick.png")}
                />
              </div>
              <p className="primary aboutUsContentText">
                Our members are fully Legal Practice Council (LPC) compliant in
                terms of the Legal Practice Act 28 of 2014
              </p>
            </div>

            <div className="aboutUsContent">
              <div className="aboutUsContentTop">
                <h1 className="primary">Forward-Thinking</h1>
                <img
                  className="aboutUsContentIcon"
                  src={require("../../../assets/images/headArrow.png")}
                />
              </div>
              <p className="primary aboutUsContentText">
                Embracing the future by establishing Virtual Chambers as a new
                platform in the legal practise and Litigation
              </p>
            </div>
          </div>
          <div className="gridBreak" />
        </div>

        <div className="aboutUsGridOuter one">
          <div className="aboutUsGrid two">
            <div className="aboutUsContent">
              <div className="aboutUsContentTop">
                <h1 className="primary">Innovative</h1>
                <img
                  className="aboutUsContentIcon"
                  src={require("../../../assets/images/light.png")}
                />
              </div>
              <p className="primary aboutUsContentText">
                Virtual Chambers have developed a new platform of interaction
                and collaborative legal practise amongst advocates providing an
                electronically advanced professional environment
              </p>
            </div>

            <div className="aboutUsContent">
              <div className="aboutUsContentTop">
                <h1 className="primary">Referrals</h1>
                <img
                  className="aboutUsContentIcon"
                  src={require("../../../assets/images/reuse.png")}
                />
              </div>
              <p className="primary aboutUsContentText">
                Receive overflow work from your colleagues
              </p>
            </div>
          </div>
          <div className="gridBreak" />
        </div>
        <div className="aboutUsGridOuter one">
          <div className="aboutUsGrid two">
            <div className="aboutUsContent">
              <div className="aboutUsContentTop">
                <h1 className="primary">Collaborative</h1>
                <img
                  className="aboutUsContentIcon"
                  src={require("../../../assets/images/userGroupPrimary.png")}
                />
              </div>
              <p className="primary aboutUsContentText">
                We share intellectual capital, experience and collegiality on a
                joint platform in the group, to ensure that our colleagues and
                Attorneys have the benefit of our Professional resource
                collaboration
              </p>
            </div>

            <div className="aboutUsContent">
              <div className="aboutUsContentTop">
                <h1 className="primary">Resourceful</h1>
                <img
                  className="aboutUsContentIcon"
                  src={require("../../../assets/images/compass.png")}
                />
              </div>
              <p className="primary aboutUsContentText">
                We embrace and support virtual litigation platforms that allow
                us and our Attorneys to limit costs associated with litigation
                at a fraction of the costs to Attorneys and clients
              </p>
            </div>
          </div>
          <div className="gridBreak" />
        </div>
        <div className="aboutUsGridOuter one">
          <div className="aboutUsGrid two">
            <div className="aboutUsContent">
              <div className="aboutUsContentTop">
                <h1 className="primary">Committed</h1>
                <img
                  className="aboutUsContentIcon"
                  src={require("../../../assets/images/lineChart.png")}
                />
              </div>
              <p className="primary aboutUsContentText">
                We are committed to create a shared experience, collaborative
                development, growth and guidance to our members, from the young
                and technically savvy, to our more senior members and vice
                versa
              </p>
            </div>

            <div className="aboutUsContent">
              <div className="aboutUsContentTop">
                <h1 className="primary">Independent</h1>
                <img
                  className="aboutUsContentIcon"
                  src={require("../../../assets/images/flag.png")}
                />
              </div>
              <p className="primary aboutUsContentText">
                We associate independently with our peers and colleagues, whilst
                still being affiliated to a group of Advocates and respected
                counsel, who are committed to serve, grow and embrace the
                Judiciary
              </p>
            </div>
          </div>
          <div className="gridBreak" />
        </div>
      </div>
    </div>
  );
}

export default AboutUs
