import React from 'react';
import AuthenticationProvider from './authenticationProvider';
import ModalProvider from './modalProvider'

export const AppProvider: React.FC = ({children}) => {
    return  <AuthenticationProvider>
                <ModalProvider>
                    {children}
                </ModalProvider>
            </AuthenticationProvider>
}