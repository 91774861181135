import React, { useEffect, useState } from 'react';
import moment from "moment";

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';
import endPoints from "../../../services/api/root/endPoints";
import {globalLocalStorage} from "../../../services/storage/localStorage";
import {loginCheckService} from "../../../services/checkLogin";

const Courtrolls = () =>{

    const [rolls, setRolls] = useState([])

    useEffect(() => {

      loginCheckService.check();
        let user = globalLocalStorage.getItem("user");
        if (!user)
          window.location.href = '/home'
        endPoints.getCourtrollscats().then((res:any) => {
            if (res.success) {
                setRolls(res.message)
            }

       })
      }, [])


  return (
    <div>
      <div className = 'holder top'>
        <div className = 'lectures one'>
            <div className = 'two currentLectures'>
                <div className = 'left'>
                    <h1 className = 'primary'>Court Rolls </h1>
                    {rolls.map((item:any, index) => {
                    return <div className="skillsListDropdown" key={item.id}>
                        <a target="_blank "href= {`${item.link}`}>
                        <div className = 'flexRow spaceBetween'>
                            <h3 className = 'primary bold'><a target="_blank " className = 'noLinkDeco primary' href= {`${item.link}`}>{item.name}</a></h3>
                            <img id = 'chevron' className =  'chevron' src = {require('../../../assets/images/chevronBlue.png')}/>
                        </div>
                        </a>
                    </div>})}
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Courtrolls