import React from 'react';

interface props{
    label: string;
    onClick: any;
}

export const DefaultBtn = (Props: props) =>{


  return(
    <div className = 'defaultBtnContainer'>
        <button onClick = {Props.onClick} className = 'white defaultBtn'>{Props.label}</button>
    </div>
  )
}
