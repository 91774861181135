import { WebsiteNav } from './websiteNav/websiteNav';
import { WebsiteFooter } from './websiteFooter/websiteFooter';
import { NoticeBoard } from './noticeBoard/noticeBoard';
import { DashboardCommonRoom } from './dashboardCommonRoom/dashboardCommonRoom';
import { LecturesTable } from './lecturesTable/lecturesTable';
import { DashboardLecturesTable } from './dashboardLecturesTable/dashboardLecturesTable';
import { IndustryNews } from './industryNews/industryNews';
import { CommonRoomsCard } from './commonRoomCard/commonRoomCard';
import { MembersCard } from './membersCard/membersCard';
import { UserMeetingRequest } from './userMeetingRequest/userMeetingRequest';
import { UserMultiMeetingRequest } from './userMultiMeetingRequest/userMultiMeetingRequest';
import { CustomModal } from './customModal/customModal';
import { ConfirmationModal } from './confirmationModal/confirmationModal';


import { SignUpStepOne } from './login/signUpStepOne';
import { SignUpStepTwo } from './login/signUpStepTwo';
import { SignUpStepThree } from './login/signUpStepThree';

export default {
  WebsiteNav,
  WebsiteFooter,
  NoticeBoard,
  DashboardCommonRoom,
  LecturesTable,
  DashboardLecturesTable,
  IndustryNews,
  CommonRoomsCard,
  MembersCard,
  SignUpStepOne,
  SignUpStepTwo,
  SignUpStepThree,
  UserMeetingRequest,
  UserMultiMeetingRequest,
  CustomModal,
  ConfirmationModal
};
