import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "./restRequests";
import { globalLocalStorage } from "../../../services/storage/localStorage";
import axios from "axios";

export default {
  //user

  register: (fields: any) => {
    return new Promise((resolve, reject) => {
      postRequest(
        `${process.env.REACT_APP_API_URL}/auth/local/register`,
        fields
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  login: (fields: any) => {
    return new Promise((resolve, reject) => {
      postRequest(`${process.env.REACT_APP_API_URL}/auth/local`, fields)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  updateProfile: (fields: any) => {
    return new Promise((resolve, reject) => {
      let token: any = globalLocalStorage.getItem("token");
      let user: any = globalLocalStorage.getItem("user");
      user = JSON.parse(user);
      token = token.replace(/"/g, "");

      putRequest(
        `${process.env.REACT_APP_API_URL}/users/${user.id}`,
        fields,
        token
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  forgotPassword: (fields: any) => {
    return new Promise((resolve, reject) => {
      postRequest(
        `${process.env.REACT_APP_API_URL}/auth/forgot-password`,
        fields
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  resetPassowrd: (fields: any) => {
    return new Promise((resolve, reject) => {
      postRequest(
        `${process.env.REACT_APP_API_URL}/auth/reset-password`,
        fields
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  //zomm

  zoomauthorize: (fields: any) => {
    return new Promise((resolve, reject) => {
      postRequest(
        `${process.env.REACT_APP_API_URL}/zoomaccounts/authorize`,
        fields
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  fetchandstoreaccesstoken: (fields: any) => {
    return new Promise((resolve, reject) => {
      let token: any = globalLocalStorage.getItem("token");
      token = token.replace(/"/g, "");

      postRequest(
        `${process.env.REACT_APP_API_URL}/zoomaccounts/accesstoken`,
        fields,
        token
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  meetings: (fields: any) => {
    return new Promise((resolve, reject) => {
      let token: any = globalLocalStorage.getItem("token");
      token = token.replace(/"/g, "");

      postRequest(
        `${process.env.REACT_APP_API_URL}/meetings`,
        fields,
        token
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  createKnockKnock: (fields: any) => {
    // /knock-knocks
    return new Promise((resolve, reject) => {
      let token: any = globalLocalStorage.getItem("token");
      token = token.replace(/"/g, "");

      postRequest(
        `${process.env.REACT_APP_API_URL}/knock-knocks`,
        fields,
        token
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  createKnockComment: (fields: any) => {
    // /knock-knocks
    return new Promise((resolve, reject) => {
      let token: any = globalLocalStorage.getItem("token");
      token = token.replace(/"/g, "");

      postRequest(
        `${process.env.REACT_APP_API_URL}/knock-messages`,
        fields,
        token
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  createKnockSubscriber: (fields: any) => {
    // /knock-knocks
    return new Promise((resolve, reject) => {
      let token: any = globalLocalStorage.getItem("token");
      token = token.replace(/"/g, "");

      postRequest(
        `${process.env.REACT_APP_API_URL}/knock-knock-subscribers`,
        fields,
        token
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  updateKnockViews: (fields: any, id: any) => {
    return new Promise((resolve, reject) => {
      let token: any = globalLocalStorage.getItem("token");
      token = token.replace(/"/g, "");

      putRequest(
        `${process.env.REACT_APP_API_URL}/knock-knocks/${id}`,
        fields,
        token
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  deleteKnockSubscriber: (id: number) => {
    return new Promise((resolve, reject) => {
      let token: any = globalLocalStorage.getItem("token");
      token = token.replace(/"/g, "");
      deleteRequest(
        `${process.env.REACT_APP_API_URL}/knock-knock-subscribers/${id}`,
        token
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  // /knock-knocks/:id

  getKnockKnocks: () => {
    return new Promise((resolve, reject) => {
      let token: any = globalLocalStorage.getItem("token");
      token = token.replace(/"/g, "");
      getRequest(`${process.env.REACT_APP_API_URL}/knock-knocks`, token)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  knock: (id: number) => {
    return new Promise((resolve, reject) => {
      let token: any = globalLocalStorage.getItem("token");
      token = token.replace(/"/g, "");
      getRequest(
        `${process.env.REACT_APP_API_URL}/knock-knocks/${id}`,
        token
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  //

  roomrequests: (fields: any) => {
    return new Promise((resolve, reject) => {
      let token: any = globalLocalStorage.getItem("token");
      token = token.replace(/"/g, "");

      postRequest(
        `${process.env.REACT_APP_API_URL}/roomrequests`,
        fields,
        token
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  lecturerequests: (fields: any) => {
    return new Promise((resolve, reject) => {
      let token: any = globalLocalStorage.getItem("token");
      token = token.replace(/"/g, "");

      postRequest(
        `${process.env.REACT_APP_API_URL}/lecturerequests`,
        fields,
        token
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  contactmessages: (fields: any) => {
    return new Promise((resolve, reject) => {
      postRequest(
        `${process.env.REACT_APP_API_URL}/contact-messages`,
        fields
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  // Blogs
  getBlogs: () => {
    return new Promise((resolve, reject) => {
      getRequest(`${process.env.REACT_APP_API_URL}/blogs`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  blog: (id: number) => {
    return new Promise((resolve, reject) => {
      getRequest(`${process.env.REACT_APP_API_URL}/blogs/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  getRss: () => {
    return new Promise((resolve, reject) => {
      getRequest(`${process.env.REACT_APP_API_URL}/rss`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  getRssSecondary: () => {
    return new Promise((resolve, reject) => {
      getRequest(`${process.env.REACT_APP_API_URL}/rss-secondary`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  getPayLink: () => {
    return new Promise((resolve, reject) => {
      let token: any = globalLocalStorage.getItem("token");
      token = token.replace(/"/g, "");
      getRequest(`${process.env.REACT_APP_API_URL}/payfast/getform`, token)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  getPayfastInfo: () => {
    return new Promise((resolve, reject) => {
      let token: any = globalLocalStorage.getItem("token");
      token = token.replace(/"/g, "");
      getRequest(`${process.env.REACT_APP_API_URL}/payfast/info`, token)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  // Rooms
  getRooms: () => {
    return new Promise((resolve, reject) => {
      getRequest(`${process.env.REACT_APP_API_URL}/rooms`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  getCourtrollscats: () => {
    return new Promise((resolve, reject) => {
      getRequest(`${process.env.REACT_APP_API_URL}/courtrollscats`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  getCourtLinks: () => {
    return new Promise((resolve, reject) => {
      getRequest(`${process.env.REACT_APP_API_URL}/court-links`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  getCourtroll: (id: number) => {
    return new Promise((resolve, reject) => {
      getRequest(`${process.env.REACT_APP_API_URL}/courtrollscats/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  // lectures
  getLectures: () => {
    return new Promise((resolve, reject) => {
      getRequest(`${process.env.REACT_APP_API_URL}/lectures`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  // notices
  getNotices: () => {
    return new Promise((resolve, reject) => {
      getRequest(`${process.env.REACT_APP_API_URL}/notices`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  // skills
  getSkills: () => {
    return new Promise((resolve, reject) => {
      getRequest(`${process.env.REACT_APP_API_URL}/skills`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  // Payments

  prepareCheckout: () => {
    return new Promise((resolve, reject) => {
      getRequest(
        `${process.env.REACT_APP_API_URL}/subscriptions/prepare-checkout`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  createTokenised: (peachTransactionId: any) => {
    return new Promise((resolve, reject) => {
      let token: any = globalLocalStorage.getItem("token");
      token = token.replace(/"/g, "");
      getRequest(
        `${process.env.REACT_APP_API_URL}/subscriptions/${peachTransactionId}/create-tokenised`,
        token
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  // me

  me: () => {
    return new Promise((resolve, reject) => {
      let token: any = globalLocalStorage.getItem("token");
      token = token.replace(/"/g, "");
      getRequest(`${process.env.REACT_APP_API_URL}/users/me`, token)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  userById: (id: number) => {
    return new Promise((resolve, reject) => {
      getRequest(`${process.env.REACT_APP_API_URL}/users/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  getMeetingParticipants: (id?: any) => {
    return new Promise((resolve, reject) => {
      getRequest(
        `${process.env.REACT_APP_API_URL}/zoom-room-participants${id ? `/${id}` : ""}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  getSingleMeetingParticipants: (id?: any) => {
    return new Promise((resolve, reject) => {
      getRequest(
        `${process.env.REACT_APP_API_URL}/zoom-room-participants${id ? `/${id}` : ""}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  getUsers: () => {
    return new Promise((resolve, reject) => {
      // let token: any = globalLocalStorage.getItem("token");
      // token = token.replace(/"/g, '')
      getRequest(`${process.env.REACT_APP_API_URL}/users`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  getMeetingRequests: () => {
    return new Promise((resolve, reject) => {
      let token: any = globalLocalStorage.getItem("token");
      token = token.replace(/"/g, "");
      getRequest(
        `${process.env.REACT_APP_API_URL}/meetingrequests/requests`,
        token
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  getMeetingRequestsInvites: () => {
    return new Promise((resolve, reject) => {
      let token: any = globalLocalStorage.getItem("token");
      token = token.replace(/"/g, "");
      getRequest(
        `${process.env.REACT_APP_API_URL}/meetingrequests/invites`,
        token
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  uploadProfilePicture: (pic: any) => {
    return new Promise((resolve, reject) => {
      let token: any = globalLocalStorage.getItem("token");
      token = token.replace(/"/g, "");
      let user: any = globalLocalStorage.getItem("user");
      user = JSON.parse(user);
      let data: any = new FormData();
      data.append("files", pic[0]);
      data.append("ref", "user");
      data.append("refId", user.id);
      data.append("source", "users-permissions");
      data.append("field", "profileImage");
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/upload`,
        data: data,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          resolve({ success: true, message: response.data });
        })
        .catch((err) => {
          reject({ success: false, message: err });
        });
    });
  },

  //Typists
  getTypists: () => {
    return new Promise((resolve, reject) => {
      getRequest(`${process.env.REACT_APP_API_URL}/typists`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  //Service Providers
  getServiceProviders: () => {
    return new Promise((resolve, reject) => {
      getRequest(`${process.env.REACT_APP_API_URL}/service-providers`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  //Youtube feeds
  getYoutubeFeeds: () => {
    return new Promise((resolve, reject) => {
      getRequest(`${process.env.REACT_APP_API_URL}/youtube-feeds`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  //POPIA
  getPopiaDocs: () => {
    return new Promise((resolve, reject) => {
      getRequest(`${process.env.REACT_APP_API_URL}/popia-docs`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
};
