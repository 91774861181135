import axios from 'axios';
import { axiosGlobalErrorHandler } from './axiosGlobalErrorHandler';

export interface stdResponse {
    success: boolean;
    message: any;
}

const httpOptions = (bearerToken?: string) => {
  return bearerToken
    ? axios.create({
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'Application/json',
        'Access-Control-Allow-Origins': '*'
      }
    })
    : axios.create({
      headers: {
        'Content-Type': 'Application/json',
        'Access-Control-Allow-Origins': '*'
      }
    });
};

export const getRequest = async (endpoint: string, token?: string, params?: {}): Promise<stdResponse> => {
  try {
    const response = await httpOptions(token).get(endpoint, { params: params });
    return axiosGlobalErrorHandler.handleHttpResponse(response);
  } catch (error) {
    return axiosGlobalErrorHandler.handleHttpResponseError(error);
  }
};

export const postRequest = async (endpoint: string, body?: any, token?: string): Promise<stdResponse> => {
  try {
    const response = await (httpOptions(token)).post(endpoint, body);
    return axiosGlobalErrorHandler.handleHttpResponse(response);
  } catch (error) {
    return axiosGlobalErrorHandler.handleHttpResponseError(error);
  }
};

export const putRequest = async (endpoint: string, body?: any, token?: string): Promise<stdResponse> => {
  try {
    const response = await (httpOptions(token)).put(endpoint, body);
    return axiosGlobalErrorHandler.handleHttpResponse(response);
  } catch (error) {
    return axiosGlobalErrorHandler.handleHttpResponseError(error);
  }
};

export const deleteRequest = async (endpoint: string, token?: string): Promise<stdResponse> => {
  try {
    const response = await (httpOptions(token)).delete(endpoint);
    return axiosGlobalErrorHandler.handleHttpResponse(response);
  } catch (error) {
    return axiosGlobalErrorHandler.handleHttpResponseError(error);
  }
};
