import React, { useEffect, useState } from 'react';

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';

import { DefaultBtn } from '../../../components/basicComponents/defaultBtn/defaultBtn';
import endPoints from "../../../services/api/root/endPoints";
import Modal from 'react-modal';
import {globalLocalStorage} from "../../../services/storage/localStorage";
import { ToastContainer, toast } from 'react-toastify';
import {loginCheckService} from "../../../services/checkLogin";

const customStylesOne = {
  content : {
    top                   : '60%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '80%',
  }
};

const inputStyles = {
  maxWidth: 320,
};

const CommonRooms = () =>{

  const [rooms, setRooms] = useState([])
  const [modalOne, setModalOne] = useState(false)
  const [meetingTitle, setMeetingTitle] = useState("")
  const [meetingDesc, setMeetingDesc] = useState("")
  const [success, setSuccess] = useState(false);

  const [meetingRequestModal, setMeetingRequestModal] = useState(false)

  function closeMeetingRequestModal(){
    setMeetingRequestModal(false);
  }

  function openMeetingRequestModal(){
    setMeetingRequestModal(true);
  }

  const openModal = () => {
    setModalOne(true);
  }
 
  function closeModalOne(){
    setModalOne(false);
    setSuccess(false);
  }

  function handleChange(e:any){
    setMeetingTitle(e.target.value)
  };


  function handleDescription(e:any){
    setMeetingDesc(e.target.value)
  };

  const createRoomRequest = async () => {

    if (meetingTitle.length < 3 || meetingDesc.length < 3) {
      toast.error("Please provide title and description", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });

      return ;
    }

    endPoints.roomrequests({
      "name": meetingTitle,
      "description": meetingDesc,
    }).then((res:any) => {
      if (res.success) {
        setSuccess(true);
    } else {
        toast.error("Failed to create a room request", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
    }
    })
  };

  useEffect(() => {
    loginCheckService.check();    
    let user = globalLocalStorage.getItem("user");
    if (!user)
      window.location.href = '/home'
    endPoints.getRooms().then((roomsRes:any) => {
      if (roomsRes.success) {
        setRooms(roomsRes.message.sort((a: any, b: any) => a.title.localeCompare(b.title)));
      }
      
   })
  }, [])

  return (
      <div className = 'commonRooms two' style={{paddingBottom: 50}}>
        <div className = 'commonRoomsLeft one'>
          <h1 className = 'primary'>Common Rooms</h1>
          {/*<div className = 'break'/>
  <h4 className = 'primary'>Permanent Rooms</h4>*/}
          <div className = 'roomsGrid three'>
            {rooms.map((item:any, index) => {
              return <EnhancedComponents.CommonRoomsCard image={item.image.url} title={item.title} description={item.description} join_url={item.join_url} key={index}/>
            })}
          </div>
          <div className = 'break' style={{marginBottom: 10}}/>
          {/*<h4 className = 'primary'>Requested Rooms</h4>
          <div className = 'roomsGrid three'>
            <EnhancedComponents.CommonRoomsCard/>
            <EnhancedComponents.CommonRoomsCard/>
            <EnhancedComponents.CommonRoomsCard/>

            <EnhancedComponents.CommonRoomsCard/>
            <EnhancedComponents.CommonRoomsCard/>
            <EnhancedComponents.CommonRoomsCard/>
          </div>*/}
          <BasicComponents.DefaultBtn onClick={openModal} label = 'Request a Room'/>
        </div>
        <div className = 'commonRoomsRight one'>
          {/* <h1 className = 'white'> Members Online</h1> */}
          {/*<BasicComponents.SecondaryButton
            label = 'Create Meeting'
            type=""
            onClick={openMeetingRequestModal}
          />*/}
        <Modal
        ariaHideApp={false}
        style={customStylesOne}
        isOpen={modalOne}
        onRequestClose={closeModalOne}
        contentLabel="Example Modal"
        >
        {!success ?
        <div  className="meetingRequestContainer">
        <h2 className = 'primary bold noMarginBottom'>Request a Room</h2>

    <h4 className = 'primary light'>Title</h4>
          <input 
          value={meetingTitle}
            onChange={handleChange}
                              className = 'defaultInput'
                            />
                            <h4 className = 'primary light'>More Information</h4>
                            <textarea onChange={handleDescription} className = 'defaultInput' rows={4} cols={50}>
                            </textarea>

                            <BasicComponents.SecondaryButton
                            label = 'Create'
                            type=""
                            onClick = {createRoomRequest}
                          />
          </div> :
          <div className="meetingRequestContainerSuccess">
          <h2 className = 'primary bold noMarginBottom'>Request a Room</h2>
          <p>Successfully created room request</p>
          <BasicComponents.SecondaryButton
            label = 'Finish'
            type=""
            onClick = {closeModalOne}
          />
        </div> }
        </Modal>

        <Modal
        ariaHideApp={false}
        style={customStylesOne}
        isOpen={meetingRequestModal}
        onRequestClose={closeMeetingRequestModal}
        >
            <EnhancedComponents.UserMultiMeetingRequest  closeMeetingRequestModal={closeMeetingRequestModal}/>
        </Modal>

      
        </div>
      </div>
  )
}

export default CommonRooms