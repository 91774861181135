import React from 'react';

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';

const OurStory = () =>{
  return (
    <div>
      <div className="top holder ourStory">
        <h1 className="line primary">Our Story</h1>
        <h3 className=" primary">Be Part of Tomorrow's Advocates Today.</h3>
        <p className = 'content primary light'>
          Every great solution starts with a problem.
        </p>
        <br/>
        <p className = 'content primary light'>
          COVID-19 was first reported from Wuhan, China, in December 2019, and it brought with it unfathomable change.
          It rapidly changed the way the world worked, socialised, lived and thought about the future.
        </p>
        <br/>
        <p className = 'content primary light'>
          Once bustling with energy and passion, advocates' chambers became ghost towns, as the world retreated into their homes waiting for the first wave to pass - and so began the birth of a new era for Advocates...
        </p>
        <br/>
        <p className = 'content primary light'>
          Sitting quietly around a boma, Ronelle and her dad, sat discussing the unknown, and how the world had shut down. Ronelle shared her fears of what lay ahead for her colleagues, clients, family and friends.
        </p>
        <br/>
        <p className = 'content primary light'>
          Taking action to secure the future…. Virtual Chambers was born.
        </p>
        <br/>
        <p className = 'content primary light'>
          Virtual Chambers was breathed into life, with the purpose of maintaining and uplifting the fraternity and keeping colleagues connected.
          Over the course of a year, Ronelle Ferguson, Bongiwe Dube and Rene Janse van Rensburg collaborated on the new Advocates' journey, implementing technology, experience and the culture of advocacy, to ensure success, sustainability and growth.
        </p>
        <br/>
        <p className = 'content primary light'>
          Virtual Chambers was conceptualised to become a working environment that would outlive the pandemic.
        </p>
        <br/>
        <p className = 'content primary light'>
          As Ronelle says, "If you cannot change the direction of the wind… then change the direction of your sails”
        </p>
        <br/>
        <p className = 'content primary light'>
          – Virtual Chambers
        </p>
        <div className="three gcg20">
          <div className="card">
            <img className="founderImg" src={require("../../../assets/images/BONGIWE.jpg")}/>
            <h2 className="primary tcenter">Bongiwe Dube</h2>
            <p className="primary tcenter">Founder</p>
          </div>
          <div className="card">
            <img className="founderImg" src={require("../../../assets/images/RONELLE.jpg")}/>
            <h2 className="primary tcenter">Ronelle Ferguson</h2>
            <p className="primary tcenter">Founder</p>
          </div>
          <div className="card">
            <img className="founderImg" src={require("../../../assets/images/RENE.jpg")}/>
            <h2 className="primary tcenter">Rene Janse Van Rensburg</h2>
            <p className="primary tcenter">Founder</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurStory