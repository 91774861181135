import React from "react";

import EnhancedComponents from "../../../components/enhancedComponents/index";
import BasicComponents from "../../../components/basicComponents/index";

const CookiePolicy = () => {
  return (
    <div className="top holder primary">
      <div className="">
        <h1 className="line primary">COOKIES</h1>
      </div>

      <p>
        We may obtain information about your internet usage by using a “cookie
        file” which is stored on the hard drive of your personal computer.
        “Cookies” refer to information that is sent from our website to your
        personal computer’s hard drive where it is saved and contains
        information to personalise your experience on our website. Accordingly,
        the next time you visit our website, we will recognise you and that you
        have visited our website before.
        <br /> <br />
        Cookies also help us to improve our website and to deliver a better and
        more personalised service. In this regard, they also enable us to:
      </p>

      <ul>
        <li>Estimate our audience size and usage pattern;</li>
        <li>
          Store information regarding your preferences, which allows us to
          tailor your experience according to your individual interests;
        </li>
        <li>
          Store information regarding your Virtual Chambers search history, and
          use said information to enhance your experience;
        </li>
      </ul>

      <p>
        You may refuse to accept cookies by activating the setting on your
        browser which allows you to refuse the setting of cookies. However, if
        you select this setting you may be unable to access certain parts of our
        website. Unless you have adjusted your browser settings so that it will
        refuse cookies, our system will issue cookies when you visit our
        website.
      </p>

      <h3 className="primary bold" style={{ marginTop: 20, marginBottom: 20 }}>
        POPIA aims to promote the protection of your personal information as
        processed by public and private bodies and seeks to introduce minimum
        requirements for the processing of personal information.
      </h3>
    </div>
  );
};

export default CookiePolicy;
