import React, { useEffect, useState } from 'react';

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';
import { DefaultBtn } from '../../../components/basicComponents/defaultBtn/defaultBtn';
import endPoints from "../../../services/api/root/endPoints";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { globalLocalStorage } from '../../../services/storage/localStorage';

interface UserObject {
    first_name: string
    last_name: string
    email: string
    password: string
    confirm_password: string,
    mobile: string,
    location: string;
  }

const Loader = require('react-loader');

const Registration = () =>{

    const [loaded, setLoaded] = useState<any>(true);
    const [accountCreated, setAccountCreated] = useState<any>(false);
    const [error, setError] = useState(null);
    const [showPass, setShowPass] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const phoneRegExp = /^\+?[0-9]\d{9,14}$/g;
    var passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  
    const handleSubmit = async (fields: UserObject) => {
      setLoaded(false);
      endPoints.register({
        username: fields.email,
        email: fields.email,
        name: fields.first_name,
        surname: fields.last_name,
        password: fields.password,
        phone: fields.mobile,
        location: fields.location,
      }).then((res:any) => {
        setLoaded(true);
        if (res.success) {
            globalLocalStorage.storeItem("user", JSON.stringify(res.message.user));
            globalLocalStorage.storeItem("token", JSON.stringify(res.message.jwt));
          // Props.closeModalOne();
          setAccountCreated(true);
          // alert("Your account have been created please check your email");
          // toast.success("Your account have been created please check your email", {
          //   position: "top-center",
          //   autoClose: 3000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: false,
          //   progress: undefined,
          // });
        }else {
          setError(res.message.data.data[0].messages[0].message);
        }
     });
    };


  return (
    <div>
        <div className = 'top holder contactUs'>
            <h1 className = 'line primary'>Join Virtual Chambers</h1>
            <div className = 'one' style={{minHeight: 250}}>

                <Loader loaded={loaded}>
                {!accountCreated ? <Formik
                initialValues={{
                    first_name: '',
                    last_name: '',
                    email: '',
                    mobile:'',
                    password: '',
                    confirm_password: '',
                    location: ''
                }}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    first_name: Yup.string().required("Name is required"),
                    last_name: Yup.string().required("Surname is required"),
                    email: Yup.string()
                      .email("Email is invalid")
                      .required("Email is required"),
                    mobile: Yup.string()
                      .matches(phoneRegExp, "Mobile number is not valid")
                      .required("Mobile number is required"),
                    password: Yup.string()
                      .matches(
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                        )
                      .required('Password is required'),
                    confirm_password: Yup.string()
                        .oneOf([Yup.ref('password'), ''], 'Passwords must match')
                        .required('Confirm Password is required'),
                    location: Yup.string().required('Location is required')
                })}
                onSubmit={handleSubmit}
                >
                  {(formik: any) => {
                    const {
                      values,
                      handleChange,
                      handleSubmit,
                      errors,
                      touched,
                      handleBlur,
                      isValid,
                      dirty,
                    } = formik;
                    return (
                        <Form>
                        {error && <div className="error">{error}</div>}
                        <div className = 'one'>
                            <h4 className = 'secondary medium'>Become a Member</h4>
                            <input 
                              placeholder = 'Name'
                              name = 'first_name'
                              type = 'text'
                              id= 'first_name'
                              value={values.first_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className = 'defaultInput'
                            />

                            <ErrorMessage name="first_name">
                              {(msg) => <div className="error">{msg}</div>}
                            </ErrorMessage>

                            <input 
                              placeholder = 'Surname'
                              name = 'last_name'
                              type = 'text'
                              id= 'last_name'
                              value={values.last_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className = 'defaultInput'
                            />

                            <ErrorMessage name="last_name">
                              {(msg) => <div className="error">{msg}</div>}
                            </ErrorMessage>

                            <input 
                              placeholder = 'Email Address'
                              name = 'email'
                              type = 'text'
                              id= 'email'
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className = 'defaultInput'
                            />
                            
                            <ErrorMessage name="email">
                              {(msg) => <div className="error">{msg}</div>}
                            </ErrorMessage>

                            <input 
                              placeholder = 'Mobile Number'
                              name = 'mobile'
                              type = 'text'
                              id= 'mobile'
                              value={values.mobile}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className = 'defaultInput'
                            />

                            <ErrorMessage name="mobile">
                              {(msg) => <div className="error">{msg}</div>}
                            </ErrorMessage>
                            <input 
                              placeholder = 'Location'
                              name = 'location'
                              type = 'text'
                              id= 'location'
                              value={values.location}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className = 'defaultInput'
                            />
                            <ErrorMessage name="location">
                              {(msg) => <div className="error">{msg}</div>}
                            </ErrorMessage>
                            <div className = 'passwordInput'>
                              <input 
                                placeholder = 'Password'
                                name = 'password'
                                type = {showPass ? 'text':'password'}
                                id= 'password'
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className = 'defaultInput'
                              />
                            <i className='fa fa-eye showPassword' onClick={() => setShowPass(!showPass)}/>
                            </div>

                            <ErrorMessage name="password">
                              {(msg) => <div className="error">{msg}</div>}
                            </ErrorMessage>
                            <div className = 'passwordInput'>
                              <input 
                                placeholder = 'Confirm Password'
                                name = 'confirm_password'
                                type = {showConfirm ? 'text' : 'password'} 
                                id= 'confirm_password'
                                value={values.confirm_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className = 'defaultInput'
                              />
                              <i className='fa fa-eye showPassword' onClick={() => setShowConfirm(!showConfirm)}/>
                            </div>
                            <ErrorMessage name="confirm_password">
                              {(msg) => <div className="error">{msg}</div>}
                            </ErrorMessage>

                            <p className="acceptTos"><input type="checkbox" required/> I accept the <a href="/tos" target="_blank"><u>Terms and Conditions</u></a></p>
                            <BasicComponents.SecondaryButton
                              label = 'Continue'
                              type="submit"
                              onClick = {null}
                            />
                        </div>
                      </Form>
                );
            }}
          </Formik> :
    <div className = 'signUpModal'>
      <h2 className = 'secondary medium tcenter'>Check Your Email</h2>
      <p className = 'tcenter'>
      Click the link in the email from Virtual Chambers to continue the registration process.
      </p>
    </div> }
          </Loader>
            </div>
        </div>  
    </div>
  )
}

export default Registration
