import React, { useEffect } from 'react';
// @ts-ignore
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Routes from './routes/routes';
import './App.css';
import './index.scss'
import { AppProvider } from './context';
import { useAuthenticationProvider } from './context/authenticationProvider';
import {globalLocalStorage} from "./services/storage/localStorage";
import {useLocation} from "react-router-dom";
import { ToastContainer } from 'react-toastify';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#1884F0' },
    secondary: { main: '#001C38' }
  }

});

const App = (props:any) => {

  return (
    <AppProvider>
      <MuiThemeProvider theme={theme}>
        <Routes/>
        <ToastContainer />
      </MuiThemeProvider>
    </AppProvider>
  );
};

export default App;
