import { globalLocalStorage } from "./storage/localStorage";
import endPoints from "./api/root/endPoints";

const check = () => {
    let user: any = globalLocalStorage.getItem("user");
    let token: any = globalLocalStorage.getItem("token");

    if (user && token) {
        user = JSON.parse(user);
        endPoints.me().then((res: any) => {
            if (!res.success) {
                globalLocalStorage.removeItem("user");
                globalLocalStorage.removeItem("token");
                window.location.href = "/home";
            }
        });
    }else {
        globalLocalStorage.removeItem("user");
        globalLocalStorage.removeItem("token");
        window.location.href = "/home";
    }
};

export const loginCheckService = {
    check,
}