import React, { useEffect, useState } from 'react';
import moment from "moment";
import { useParams } from 'react-router-dom';

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';
import endPoints from "../../../services/api/root/endPoints";
import {globalLocalStorage} from "../../../services/storage/localStorage";
import { Link, NavLink } from 'react-router-dom';

const Courtroll = () =>{

    const [roll, setRoll] = useState({
      courtrolls: [],
      name: ''
    })
    const { id } = useParams();

    useEffect(() => {

        let user = globalLocalStorage.getItem("user");
        if (!user)
          window.location.href = '/home'
        endPoints.getCourtroll(id).then((res:any) => {
            if (res.success) {
                setRoll(res.message);
            }

       })
      }, [])


  return (
    <div>
      <div className = 'holder top'>
        <div className = 'lectures one'>
            <div className = 'two currentLectures'>
                <div className = 'left'>
                    <h1 className = 'primary'>{roll.name}'s Court Rolls</h1>


                    {roll.courtrolls.map((item:any, index) => {
                    return <div className="skillsListDropdown" key={item.id}>
                        <a target="_blank "href= {`${process.env.REACT_APP_API_URL}${item.file.url}`}>
                        <div className = 'flexRow spaceBetween'>
                            <h3 className = 'primary bold'><a target="_blank " className = 'noLinkDeco primary' href= {`${item.link}`}>{item.name}</a></h3>
                            <img id = 'chevron' className =  'chevron' src = {require('../../../assets/images/chevronBlue.png')}/>
                        </div>
                        </a>
                    </div>})}

                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Courtroll