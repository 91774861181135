import React from 'react';

import { Link } from 'react-router-dom';

interface Props {
    name: string;
    surname: string;
    number: string;
    email: string;
    user: any;
}

export const MembersCard = (props: Props) =>{
  return(
    <div className = 'membersCard'>
        <img className = 'membersThumbnail' src = {`${process.env.REACT_APP_API_URL}${props.user.profileImage.url}`}/>
        <h4 className = 'white name'>{props.name} {props.surname}</h4>
        <div className = 'overlayDark'/>
        <div className = 'overlay'>
            <div className = 'content'>
                <h4 className = 'secondary noMargin'>{props.name}</h4>
                <p className = 'secondary'>{props.number}</p>
                <p className = 'secondary'>{props.email}</p>
            </div>
        </div>
    </div>
  )
}