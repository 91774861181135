import React from 'react';

interface props{

    name: string;

}

export const CommonRoomUser = (Props: props) =>{
    

  return(
    <div className = 'commonRoomUser three'>
        <div className = 'profile '>    
          <img src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAAvCAYAAABzJ5OsAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAL6ADAAQAAAABAAAALwAAAADLnOTNAAACr0lEQVRoBe2ZS0hUYRTH54ppBr0GiulJJGFItLSEXgbRpjZBrQL3tQhculJwIW1nFS2CFu11IZUx1SZqIRRBCEkwPRZREz0MCaHxd0a9jFdn7ne+73O8F+bAn/v6n3N+9+B81zuTyXiMcrl8Cd1Hb9EPNIdm0GN0A2U9tnMvBVAbuok+o7j4hyGP9rh3dqwAxG70BmnjNwlnHNvbp9O8E33RUkf8V+wJLDMByKGPERCbw3mSLlhi2KXRsGBDWiPnJ+e32ZC0aJNoJJPq0+bV8W/n2mCd6/4uAT9eY4Iup2X66kGqEmiwiTFc9DeKsJJM/3R4ZLijgqfmSdRmWFtrO6tN0MLntA0U/sMKb8WqhbdaFQyh9hr6QpsW/nuY6X+npC2phf+kbaDwFxXeilUL/5qsX9omhv7nhr7QpoIPguA/mQ/DbH87c5R6oi2ngl8qntc2MfDfYTDzBj53Cw+rCZfHaSR3luOd7lSGFWh2AJUiELaH1wzb+rNBes6Wtipv2B+RshIQ3Wi6CsZ09w/Gq8p2/u1AtKNRJC8WJvEI0z4fJIGPIlIDoKNsLqNedArtQhJ/0Sv0AhVYVZ6yTXZwM/KhPp5syiZdcwLNCTRsAk5LJavJVkjlvfYIOoRkuexE3ag65D1gGn1AM0vbKZbNIvvWoYIHdjOd5C3/POpDJ5BLvCf5GZpE8gxQv02RVz+Avo4m0XrHFA0G0I76RDFXKSDfR46gr2gj4h5Nj8VgrrxMQhbdRUmJMUC6VlKucYRJftn4lhTqCMetNZAXT2EcjJiTePhg1Q1A2Z9E0hpMt5dvIMBwkANZgzuWT6Zg28uy+lK+PRhCaQKX2VamL5OXB0OyfmIUvPjYL5NPI7jcWo/Nl07xM2mMY0ua4TNN+Mb8lazukurJt3I/8n95GuPdAsqq7DEk+f7gAAAAAElFTkSuQmCC'/>
        </div>
        <p className = 'name semi-bold primary'>{Props.name.slice(0, 20)}{Props.name.length > 20 ? '...' : ''}</p>
        {/*<div className = 'activity'/>*/}
    </div>
  )
}
