import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";

import { useAuthenticationProvider } from "../../../context/authenticationProvider";
import endPoints from "../../../services/api/root/endPoints";
import { globalLocalStorage } from "../../../services/storage/localStorage";
import EnhanacedComponents from "../../enhancedComponents/index";
import mobileBanner from "../../../assets/images/mobile_maintenance.png";
import { useModalProvider } from "../../../context/modalProvider";
import NavPanel from "../navPanel/navPanel";

const customStylesOne = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    height: "80%",
  },
};

const customStylesTwo = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    maxHeight: "80%",
  },
};

const customStylesThree = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    padding: "30px",
    height: "15vh",
  },
};

export const WebsiteNav = () => {
  const { auth, authSet } = useAuthenticationProvider();
  const [dropdown, setDropdown] = useState(false);
  const [canViewMembers, setCanViewMembers] = useState(false);
  const [modalOne, setModalOne] = useState(false);
  const [modalTwo, setModalTwo] = useState(false);
  const [modalThree, setModalThree] = useState(false);
  const [globalUser, setGlobalUser] = useState({ name: "" });
  const { active, toggleModal } = useModalProvider();
  const [navActive, setNavActive] = useState(false);

  const logout = () => {
    globalLocalStorage.removeItem("user");
    globalLocalStorage.removeItem("token");
    authSet(false);
    window.location.href = "/home";
  };

  useEffect(() => {
    let user: any = globalLocalStorage.getItem("user");
    let token: any = globalLocalStorage.getItem("token");
    if (user) user = JSON.parse(user);
    setGlobalUser(user);
    // setModalTwo(true);
    if (token) {
      authSet(true);
      endPoints.me().then((res: any) => {
        if (res.success) {
          endPoints.userById(res.message.id).then((result: any) => {
            if (result.success) {
              endPoints.getPayfastInfo().then((payfastResult: any) => {
                if (payfastResult.success) {
                  console.log(result);
                  if (!result.message.advocateDateOfAdmission) {
                    setModalTwo(true);
                  } else if (!result.message.profileImage) {
                    setModalThree(true);
                  }
                } else {
                  if (!result.message.advocateDateOfAdmission) {
                    setModalTwo(true);
                  } else if (
                    !result.message.profileImage ||
                    (result.message.payfastpayments.length === 0 &&
                      !result.message.guest &&
                      !result.message.influencer)
                  ) {
                    console.log(result.message);
                    setModalThree(true);
                  }
                }
                if (
                  result.message.advocateDateOfAdmission &&
                  result.message.profileImage &&
                  (result.message.payfastpayments.length > 0 ||
                    result.message.guest ||
                    result.message.influencer)
                ) {
                  setCanViewMembers(true);
                }
              });
            }
          });
        }
      });
    } else authSet(false);
  }, []);

  const openModal = () => {
    setModalOne(true);
  };

  function closeModalOne() {
    setModalOne(false);
  }

  function closeModalThree() {
    // setModalThree(false);
  }

  function openNextModal() {
    setModalOne(false);
    setModalThree(false);
    setModalTwo(false);
    let token = globalLocalStorage.getItem("token");
    if (token) {
      authSet(true);
      endPoints.me().then((res: any) => {
        if (res.success) {
          endPoints.userById(res.message.id).then((result: any) => {
            if (result.success) {
              if (!result.message.advocateDateOfAdmission) {
                setModalTwo(true);
              } else if (!result.message.profileImage) {
                setModalThree(true);
                return;
              }

              endPoints.getPayfastInfo().then((payfastResult: any) => {
                if (!payfastResult.success) {
                  // setModalThree(true);
                } else {
                  setCanViewMembers(true);
                }
              });
            }
          });
        }
      });
    }
  }

  function toggleMobileMenu() {}

  function closeModalTwo() {
    let advocateDateOfAdmission = globalLocalStorage.getItem(
      "advocateDateOfAdmission"
    );
    if (advocateDateOfAdmission) setModalTwo(false);
  }

  const closeDropdown = () => {
    setDropdown(false);
    setNavActive(false);
    document.body.style.transitionDuration = "1s";
    window.document.getElementById("overlay")!.style.backgroundColor =
      "rgba(0,0,0,0)";
    window.document.getElementById("overlay")!.style.pointerEvents = "none";
  };

  const dropdownSet = () => {
    setDropdown(!dropdown);
    if (dropdown == false) {
      document.body.style.transitionDuration = "1s";
      window.document.getElementById("overlay")!.style.backgroundColor =
        "rgba(0,0,0,0.5)";
      window.document.getElementById("overlay")!.style.pointerEvents = "unset";
    } else {
      document.body.style.transitionDuration = "1s";
      window.document.getElementById("overlay")!.style.backgroundColor =
        "rgba(0,0,0,0)";
      window.document.getElementById("overlay")!.style.pointerEvents = "none";
    }
  };

  const overlayClose = () => {
    window.document
      .getElementById("overlay")!
      .addEventListener("click", function (event) {
        setDropdown(false);
        window.document.getElementById("overlay")!.style.backgroundColor =
          "rgba(0,0,0,0)";
        window.document.getElementById("overlay")!.style.pointerEvents = "none";
      });
  };

  return (
    <div>
      <div onClick={overlayClose} className="overlay" id="overlay" />
      <div className="fixedNav">
        <div className={`${dropdown ? "active" : ""} navMemberMobile`}>
          <div>
            <NavLink
              onClick={closeDropdown}
              activeClassName="activeSubLink"
              to={"/members"}
              className="subLink primary"
            >
              Our Members
            </NavLink>
            <NavLink
              onClick={closeDropdown}
              activeClassName="activeSubLink"
              to={"/dashboard"}
              className="subLink primary"
            >
              Dashboard
            </NavLink>
            <NavLink
              onClick={closeDropdown}
              activeClassName="activeSubLink"
              to={"/lectures"}
              className="subLink primary"
            >
              Lectures & Training
            </NavLink>
            <NavLink
              onClick={closeDropdown}
              activeClassName="activeSubLink"
              to={"/common-rooms"}
              className="subLink primary"
            >
              Common Rooms
            </NavLink>
            <NavLink
              onClick={closeDropdown}
              activeClassName="activeSubLink"
              to={"/knock-knock"}
              className="subLink primary"
            >
              Knock-Knock
            </NavLink>
            <NavLink
              onClick={closeDropdown}
              activeClassName="activeSubLink"
              to={"/service-providers"}
              className="subLink primary"
            >
              Our Service Providers
            </NavLink>
            <NavLink
              onClick={closeDropdown}
              activeClassName="activeSubLink"
              to={"/typists"}
              className="subLink primary"
            >
              Our Typist Pool
            </NavLink>
            <div className="flexRow">
              <a href={"/profile"}>
                <span className="signUpButton"></span>
              </a>
              <div className="inputGroup resp-hide-rev">
                <span className="logout-two" />
                <a className="mainLink" onClick={logout}>
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="topNav">
          <div className="socialTop">
            <a
              target="_blank"
              href="https://www.facebook.com/VirtualChambersSouthAfrica"
            >
              <img
                className="socialTopIcon"
                src={require("../../../assets/images/facebookBlue.png")}
              />
            </a>
            <a
              href="https://www.instagram.com/virtual_chambers/"
              target="_blank"
            >
              <img
                className="socialTopIcon"
                src={require("../../../assets/images/instagramBlue.png")}
              />
            </a>
            <a
              href="https://www.linkedin.com/in/virtual-chambers-tomorrow-s-advocates-today-277b04215"
              target="_blank"
            >
              <img
                className="socialTopIcon"
                src={require("../../../assets/images/linkedIn.png")}
              />
            </a>
          </div>
        </div>
        <div className="mainNav">
          <div className="holderBasic flexRow">
            <div
              onClick={() => setNavActive(!navActive)}
              id="burgerMenu"
              className="burgerMenu"
            >
              <div />
              <div />
              <div />
            </div>
            <div className="navLogo">
              <Link to={"/home"}>
                <img
                  className="logo"
                  src={require("../../../assets/images/logo.png")}
                />
              </Link>
            </div>

            <ul className={`linkList relative ${navActive ? "active" : ""}`}>
              <NavLink
                onClick={closeDropdown}
                activeClassName="activeLink"
                to={"/home"}
                className="mainLink"
              >
                home
              </NavLink>
              <NavLink
                onClick={closeDropdown}
                activeClassName="activeLink"
                to={"/members"}
                className="mainLink"
              >
                Our Members
              </NavLink>
              <NavLink
                onClick={closeDropdown}
                activeClassName="activeLink"
                to={"/court-links"}
                className="mainLink"
              >
                court links
              </NavLink>
              <NavLink
                onClick={closeDropdown}
                activeClassName="activeLink"
                to={"/skills-vault"}
                className="mainLink"
              >
                Skills Vault
              </NavLink>
              <NavLink
                onClick={closeDropdown}
                activeClassName="activeLink"
                to={"/membership-info"}
                className="mainLink"
              >
                Why Join
              </NavLink>
              <NavLink
                onClick={closeDropdown}
                activeClassName="activeLink"
                to={"/about-us"}
                className="mainLink"
              >
                About Us
              </NavLink>
              {dropdown ? (
                <ul
                  className={
                    dropdown ? "dropdownMenu four active" : "dropdownMenu four"
                  }
                >
                  <NavLink
                    onClick={closeDropdown}
                    activeClassName="activeSubLink"
                    to={"/members"}
                    className="subLink primary"
                  >
                    Our Members
                  </NavLink>
                  {/* <NavLink
                    onClick={closeDropdown}
                    activeClassName="activeSubLink"
                    to={"/attorneys"}
                    className="subLink primary"
                  >
                    Our Attorneys
                  </NavLink> */}
                  <NavLink
                    onClick={closeDropdown}
                    activeClassName="activeSubLink"
                    to={"/dashboard"}
                    className="subLink primary"
                  >
                    Dashboard
                  </NavLink>
                  {/* <NavLink
                    onClick={closeDropdown}
                    activeClassName="activeSubLink"
                    to={"/skills-vault"}
                    className="subLink primary"
                  >
                    Our Skills Vault
                  </NavLink> */}

                  {/* <NavLink
                    onClick={closeDropdown}
                    activeClassName="activeSubLink"
                    to={"/juniors"}
                    className="subLink primary"
                  >
                    Our Juniors
                  </NavLink> */}
                  <NavLink
                    onClick={closeDropdown}
                    activeClassName="activeSubLink"
                    to={"/lectures"}
                    className="subLink primary"
                  >
                    Lectures & Training
                  </NavLink>
                  <NavLink
                    onClick={closeDropdown}
                    activeClassName="activeSubLink"
                    to={"/common-rooms"}
                    className="subLink primary"
                  >
                    Common Rooms
                  </NavLink>
                  {/* <NavLink
                    onClick={closeDropdown}
                    activeClassName="activeSubLink"
                    to={"/blog-archive"}
                    className="subLink primary"
                  >
                    Case Blog
                  </NavLink> */}
                  {/* <NavLink
                    onClick={closeDropdown}
                    activeClassName="activeSubLink"
                    to={"/court-rolls"}
                    className="subLink primary"
                  >
                    Court Rolls
                  </NavLink> */}
                  <NavLink
                    onClick={closeDropdown}
                    activeClassName="activeSubLink"
                    to={"/knock-knock"}
                    className="subLink primary"
                  >
                    Knock-Knock
                  </NavLink>
                  <NavLink
                    onClick={closeDropdown}
                    activeClassName="activeSubLink"
                    to={"/service-providers"}
                    className="subLink primary"
                  >
                    Our Service Providers
                  </NavLink>
                  <NavLink
                    onClick={closeDropdown}
                    activeClassName="activeSubLink"
                    to={"/typists"}
                    className="subLink primary"
                  >
                    Our Typist Pool
                  </NavLink>
                </ul>
              ) : null}
            </ul>

            <div className="navRight">
              {/*<div className = 'inputGroup'><span className="innericon searchicon"/><input placeholder = 'Search' className = 'navSearc<h' type='text'/></div>*/}
              {auth && canViewMembers ? (
                <div className="inputGroup">
                  <a className={'resp-hide'} href={"/profile"}>
                    <span className="signUpButton"></span>
                  </a>
                  <a onClick={dropdownSet}>
                    <span className="signUpButton resp-hide-rev"></span>
                  </a>
                  <button className="signUp resp-hide">
                    {auth && canViewMembers ? (
                      <a onClick={dropdownSet} className="mainLink hoverMenu">
                        Members Area
                      </a>
                    ) : null}
                  </button>
                  <div className="inputGroup resp-hide">
                    <span className="logout" />
                    <a className="mainLink" onClick={logout}>
                      logout
                    </a>
                  </div>
                  
                </div>
              ) : !auth || !canViewMembers ? (
                <div className="inputGroup">
                  <button onClick={openModal} className="signUp resp-hide">
                    SIGN IN or JOIN
                  </button>
                  <a onClick={openModal}>
                    <span className="signUpButton resp-hide-rev"></span>
                  </a>
                  <Modal
                    ariaHideApp={false}
                    isOpen={modalOne}
                    onRequestClose={closeModalOne}
                    style={customStylesOne}
                    contentLabel="Example Modal"
                  >
                    <EnhanacedComponents.SignUpStepOne
                      closeModalOne={closeModalOne}
                      openNextModal={openNextModal}
                    />
                  </Modal>
                </div>
              ) : null}
              <Modal
                ariaHideApp={false}
                isOpen={modalTwo}
                onRequestClose={closeModalTwo}
                style={customStylesTwo}
                contentLabel="Example Modal"
              >
                <EnhanacedComponents.SignUpStepTwo
                  openNextModal={openNextModal}
                />
              </Modal>
              <Modal
                ariaHideApp={false}
                isOpen={modalThree}
                onRequestClose={closeModalThree}
                style={customStylesTwo}
                contentLabel="Example Modal"
              >
                <EnhanacedComponents.SignUpStepThree
                  openNextModal={openNextModal}
                />
              </Modal>
              <Modal
                ariaHideApp={false}
                isOpen={active}
                onRequestClose={closeModalThree}
                style={customStylesThree}
                contentLabel="Example Modal"
              >
                <EnhanacedComponents.CustomModal
                  heading="Thank You"
                  content="We have succesfully registered your account"
                />
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
