import React, { useEffect, useState } from "react";
import endPoints from "../../../services/api/root/endPoints";
import moment from "moment";

import { Link } from "react-router-dom";
import basicComponents from "../../basicComponents";

export const LecturesTable = () => {
  const [currentWeekLectures, setCurrentWeekLectures] = useState([]);

  useEffect(() => {
    endPoints.getLectures().then((lecturesRes: any) => {
      if (lecturesRes.success) {
        let data = lecturesRes.message;
        const filtered = data.filter((item: any) =>
          moment(item.date).isAfter(moment())
        );
        setCurrentWeekLectures(filtered.slice(0, 5));
      }
    });
  }, []);

  return (
    <div className="topicTable four">
      <div className="topicTableHeading">
        <h1 className="white">Topics</h1>
      </div>
      <div className="topicTableHeading">
        <h1 className="white">Speaker</h1>
      </div>
      <div className="topicTableHeading">
        <h1 className="white">Date & Time</h1>
      </div>
      <div className="topicTableHeading">
        <h1 className="white">Join</h1>
      </div>
      {currentWeekLectures.map((item: any, index) => {
        return [
          <div className="topicContent right" key={item.title}>
            <p className="center primary">{item.title}</p>
          </div>,
          <div className="topicContent right" key={item.speaker}>
            <p className="center primary">{item.speaker}</p>
          </div>,
          <div className="topicContent right" key={item.date}>
            <p className="primary">{`${moment(item.date).format(
              "YYYY-MM-DD"
            )} - ${moment(item.date).format("h:mm a")}`}</p>
          </div>,
          <div
            className="topicContent"
            key={item.date + "-time"}
          >
            <a href={item.join_url} target={"_blank"} rel={"noreferrer"} style={{textDecoration: 'none'}}>
              <div className="defaultBtnContainer">
                <button onClick={() => {}} className="white defaultBtn" style={{minWidth: 0}}>
                  Join
                </button>
              </div>
            </a>
          </div>,
        ];
      })}
    </div>
  );
};
