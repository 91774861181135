import React, { useEffect, useState } from 'react';

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';
import { DefaultBtn } from '../../../components/basicComponents/defaultBtn/defaultBtn';
import endPoints from "../../../services/api/root/endPoints";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
const Loader = require('react-loader');

const ContactUs = () =>{

    const [loaded, setLoaded] = useState<any>(true)

    const sendMessage = async (fields:any) => {
        setLoaded(false);
        endPoints.contactmessages({
          "email": fields.email,
          "fullname": fields.fullname,
          "message": fields.message,
        }).then((res:any) => {
          setLoaded(true);
          if (res.success) {
              toast.success("Message has been sent successfully", {
                position: "top-center",
                autoClose: 6000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
              })
          } else {
              toast.error("Failed to send contact us message", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
              });
          }
        })
    };

  return (
    <div>
        <div className = 'top p-50-20-10 contactUs'>
            <h1 className = 'line primary'>Contact Us</h1>
            <div>
                <div className = 'top two'>
                    <div className = 'left'>

                        <div className = 'flexRow'>
                            <img src = {require('../../../assets/images/phoneBlue.png')}/>
                            <p className = 'primary medium'>Call us on: <a href='tel:082 375 3284' className='white primary bold'>082 375 3284</a></p>
                        </div>

                        <div className = 'flexRow'>
                            <img src = {require('../../../assets/images/envelopeBlue.png')}/>
                            <p className = 'primary medium'>Email us on: <a href='mailto:admin@virtualchambers.co.za' className='white primary bold'>admin@virtualchambers.co.za</a></p>
                        </div>

                    </div>
                </div>
                <h2 className = 'primary'>Or Contact Us Here</h2>

                <Loader loaded={loaded}>
                <Formik
                initialValues = {{
                    fullname: "",
                    message: "",
                    email: ""
                  }}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                  fullname: Yup.string().required("Name is required"),
                  message: Yup.string().required("Message is required"),
                  email: Yup.string()
                    .email("Email is invalid")
                    .required("Email is required"),
                })}
                onSubmit={sendMessage}
                >
                  {(formik: any) => {
                    const {
                      values,
                      handleChange,
                      handleSubmit,
                      errors,
                      touched,
                      handleBlur,
                      isValid,
                      dirty,
                    } = formik;
                    return (
                        <Form style={{display: 'flex', flexDirection: 'column'}}>
                        <input name="email" id="email" value={values.email} onChange={handleChange} onBlur={handleBlur}  className = 'defaultInput' placeholder = 'Enter your email address'></input>
                        {errors.email && <div className="error">{errors.email}</div>}
                        <input name="fullname" id="fullname" value={values.fullname} onChange={handleChange} onBlur={handleBlur}  className = 'defaultInput' placeholder = 'Enter your name and surname'></input>
                        {errors.fullname && <div className="error">{errors.fullname}</div>}
                        <textarea name="message" id="message"  value={values.message} onChange={handleChange} onBlur={handleBlur}  className = 'defaultTextarea' placeholder = 'Type your message'/>
                        {errors.message && <div className="error">{errors.message}</div>}
                        <div className = 'flexEnd'>
                            <DefaultBtn onClick={null} label = 'send email'/>
                        </div>
                </Form>
                );
            }}
          </Formik>
          </Loader>
            </div>
        </div>  
    </div>
  )
}

export default ContactUs