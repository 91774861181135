import React from 'react';

import { Link } from 'react-router-dom';
import endPoints from '../../../services/api/root/endPoints';

interface Props {
    feed: {
        Name: string;
        Link: string;
    };
}

export const DashboardCommonRoom = ({feed}: Props) => {
  return(

    <div className = 'dashboardCommonRoom dashboardContainer'>
        <div className = 'one'>

            <div className = 'top'>
                <h1 className = 'primary line'>{feed.Name}</h1>
                <div className = 'right'>
                </div>
            </div>
            <iframe
                        title="Live Stream"
                        src={`https://youtube.com/embed/${feed.Link}`}
                        allowFullScreen={true}
                        width="100%"
                        height="315px"
                    />

        </div>
    </div>
  )
}