import React, { useEffect, useState } from "react";
import moment from "moment";
import Modal from "react-modal";

import EnhancedComponents from "../../../components/enhancedComponents/index";
import BasicComponents from "../../../components/basicComponents/index";
import endPoints from "../../../services/api/root/endPoints";
import { globalLocalStorage } from "../../../services/storage/localStorage";
import { ToastContainer, toast } from "react-toastify";
import { loginCheckService } from "../../../services/checkLogin";

const customStylesOne = {
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxHeight: "80%",
  },
};

const Lectures = () => {
  const [lectures, setLectures] = useState([]);
  const [currentWeekLectures, setCurrentWeekLectures] = useState([]);
  const [nextWeekLectures, setNextWeekLectures] = useState([]);
  const [modalOne, setModalOne] = useState(false);
  const [lectureTitle, setLectureTitle] = useState("");
  const [lectureDesc, setLectureDesc] = useState("");
  const [success, setSuccess] = useState(false);

  const openModal = () => {
    setModalOne(true);
  };

  function closeModalOne() {
    setModalOne(false);
    setSuccess(false);
  }

  function handleChange(e: any) {
    setLectureTitle(e.target.value);
  }

  function handleDescription(e: any) {
    setLectureDesc(e.target.value);
  }

  const createLectureRequest = async () => {
    if (lectureTitle.length < 3 || lectureDesc.length < 3) {
      toast.error("Please provide title and description", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      return;
    }

    endPoints
      .lecturerequests({
        name: lectureTitle,
        description: lectureDesc,
      })
      .then((res: any) => {
        if (res.success) {
          setSuccess(true);
        } else {
          toast.error("Failed to create lecture request", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
      });
  };

  useEffect(() => {
    loginCheckService.check();
    let user = globalLocalStorage.getItem("user");
    if (!user) window.location.href = "/home";
    endPoints.getLectures().then((lecturesRes: any) => {
      if (lecturesRes.success) {
        console.log(lecturesRes.message);
        setLectures(
          lecturesRes.message.filter((item: any) =>
            moment(item.date).isSameOrAfter(moment())
          )
        );
        let data = lecturesRes.message;

        setCurrentWeekLectures(
          data.filter((item: any) => moment(item.date).isSame(moment(), "week"))
        );
        setNextWeekLectures(
          data.filter((item: any) =>
            moment(item.date).isSame(
              moment().add(1, "weeks").startOf("isoWeek"),
              "week"
            )
          )
        );
      }
    });
  }, []);

  return (
    <div>
      <div className="holder top">
        <div className="lectures one">
          {/* <div className = 'break'/> */}
          {/* <div className = 'two currentLectures'>
                <div className = 'left'>
                    <h1 className = 'primary'>This Weeks Lecture</h1>
                    {currentWeekLectures.map((item:any, index) => {
                    return <div className = 'card two' key={item.id}>
                        <div className = 'cardTop'>
                            <h2 className = 'line primary bold'>{item.title}</h2>
                        </div>
                        <div className = 'cardLeft'>
                            {item.speaker_profile_picture == null && <img className = 'profilePicture' src = {require('../../../assets/images/tempImage.jpg')}/>}
                            {item.speaker_profile_picture != null && <img className = 'profilePicture' src = {`${process.env.REACT_APP_API_URL}${item.speaker_profile_picture.url}`}/>}
                        </div>
                        <p className = 'primary'>
                        {item.description}
                        </p>
                        
                        <div className = 'cardBottom'>
                            {item.speaker &&
                            <h4 className = 'primary medium noMarginBottom'>Speaker: </h4>}
                            {item.speaker && <h3 className = 'primary noMarginBottom'>{item.speaker}</h3>
                            }
                            <h4 className = 'primary medium noMarginBottom'>Date: </h4>
                            <h3 className = 'primary noMarginBottom'>{moment(item.date).format('MMMM Do YYYY, h:mm:ss a')}</h3>
                            <p className = 'primary medium'><a href={item.join_url} target="_blank">Join</a></p>
                        </div>
                    </div>})}
                </div>
                <div className = 'verticalBreak'/>
                <div className = 'right'>
                    <h1 className = 'primary'>Next Weeks Lecture</h1>
                    {nextWeekLectures.map((item:any, index) => {
                        return <div className = 'card two' key={item.id}>
                            <div className = 'cardTop'>
                                <h2 className = 'line primary bold'>{item.title}</h2>
                            </div>
                            <div className = 'cardLeft'>
                                {item.speaker_profile_picture == null && <img className = 'profilePicture' src = {require('../../../assets/images/tempImage.jpg')}/>}
                                {item.speaker_profile_picture != null && <img className = 'profilePicture' src = {`${process.env.REACT_APP_API_URL}${item.speaker_profile_picture.url}`}/>}
                            </div>
                            <p className = 'primary'>
                            {item.description}
                            </p>
                            
                            <div className = 'cardBottom'>
                            {item.speaker &&
                                <h4 className = 'primary medium noMarginBottom'>Speaker: </h4>}
                                {item.speaker && <h3 className = 'primary noMarginBottom'>{item.speaker}</h3>
                                }
                                <h4 className = 'primary medium noMarginBottom'>Date: </h4>
                                <h3 className = 'primary noMarginBottom'>{moment(item.date).format('MMMM Do YYYY, h:mm:ss a')}</h3>
                                <p className = 'primary medium'><a href={item.join_url} target="_blank">Join</a></p>
                            </div>
                        </div>})}
                </div>
            </div> */}
          <div className="flexRow top">
            <h1 className="primary noMargin">Upcoming Lectures</h1>
            <BasicComponents.DefaultBtn
              onClick={openModal}
              label="request a topic"
            />
          </div>
          <div className="break" />
          <div className="upcoming-lectures three">
            {lectures.map((item: any, index) => {
              return (
                <a
                    href={item.join_url}
                    target={"_blank"}
                    rel={"noreferrer"}
                    className='lectureJoinBtn'
                  >
                <div className="card" key={item.id}>
                  <div style={{ position: "relative" }}>
                    <div className="cardTop">
                      <h3
                        className="line primary bold"
                        style={{ width: "auto" }}
                      >
                        {item.title}
                      </h3>
                    </div>

                    <div className="cardBottom">
                      {item.speaker && (
                        <h5 className="primary medium noMarginBottom">
                          Speaker:{" "}
                        </h5>
                      )}
                      {item.speaker && (
                        <h4 className="primary noMarginBottom">
                          {item.speaker}
                        </h4>
                      )}
                      <h5 className="primary medium noMarginBottom">Date: </h5>
                      <h4 className="primary noMarginBottom">
                        {moment(item.date).format("MMMM Do YYYY, h:mm a")}
                      </h4>
                    </div>
                  </div>

                  <div className="image">
                    {item.speaker_profile_picture == null && (
                      <img
                        className="profilePicture"
                        src={require("../../../assets/images/tempImage.jpg")}
                      />
                    )}
                    {item.speaker_profile_picture != null && (
                      <img
                        className="profilePicture"
                        src={`${process.env.REACT_APP_API_URL}${item.speaker_profile_picture.url}`}
                      />
                    )}
                  </div>
                </div>
                </a>
              );
            })}
          </div>
        </div>

        <Modal
          ariaHideApp={false}
          style={customStylesOne}
          isOpen={modalOne}
          onRequestClose={closeModalOne}
          contentLabel="Example Modal"
        >
          {!success ? (
            <div className="meetingRequestContainer">
              <h2 className="primary bold noMarginBottom">Request a Topic</h2>

              <h4 className="primary light">Topic Title</h4>
              <input
                value={lectureTitle}
                onChange={handleChange}
                className="defaultInput"
              />
              <h4 className="primary light">More Information</h4>
              <textarea
                onChange={handleDescription}
                className="defaultInput"
                rows={4}
                cols={50}
              ></textarea>

              <BasicComponents.SecondaryButton
                label="Create"
                type=""
                onClick={createLectureRequest}
              />
            </div>
          ) : (
            <div className="meetingRequestContainerSuccess">
              <h2 className="primary bold noMarginBottom">Request a Topic</h2>
              <p>Your topic request has been sent</p>
              <BasicComponents.SecondaryButton
                label="Finish"
                type=""
                onClick={closeModalOne}
              />
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Lectures;
