import React from 'react';
import { BrowserRouter, Route,  Switch } from 'react-router-dom';

import ScrollToTop from '../scripts/scrollToTop'

import EnhancedComponents from '../components/enhancedComponents/index';

import Homepage from '../containers/website/homepage/homepage';
import AboutUs from '../containers/website/aboutUs/aboutUs';
import WhyJoin from '../containers/website/whyJoin/whyJoin';
import ContactUs from '../containers/website/contactUs/contactUs';
import Dashboard from '../containers/website/dashboard/dashboard';
import Lectures from '../containers/website/lectures/lectures';
import CommonRooms from '../containers/website/commonRooms/commonRooms';
import Attorneys from '../containers/website/attorneys/attorneys';
import Juniors from '../containers/website/juniors/juniors';
import SkillsVault from '../containers/website/skillsVault/skillsVault';
import ProfilePage from '../containers/website/profilePage/profilePage';
import Members from '../containers/website/members/members'
import Profile from '../containers/website/profile/profile';
import Courtrolls from '../containers/website/courtrolls/courtrolls';
import Courtroll from '../containers/website/courtroll/courtroll';
import Tos from '../containers/website/tos/tos';
import Privacy from '../containers/website/privacy/privacy';
import Blog from '../containers/website/blog/blog';
import Courtlinks from '../containers/website/courtlinks/courtlinks';
import MembershipInfo from '../containers/website/membershipInfo/membershipInfo';
import BlogArchive from '../containers/website/blogArchive/blogArchive';
import Registration from '../containers/website/registration/registration';
import ResetPassword from '../containers/website/resetPassword/resetPassword';
import Knockknock from '../containers/website/knock-knock/knockknock';
import Knock from '../containers/website/knock/knock';
import OurStory from '../containers/website/ourStory/ourStory';
import ServiceProviders from '../containers/website/serviceProviders/serviceProviders';
import Typists from '../containers/website/typists/typists';
import Popia from '../containers/website/popia/popia'
import CookiePolicy from '../containers/website/cookiePolicy/cookiePolicy';
import mobileMode from '../assets/images/mobile_maintenance.png'

const Routes = () => {
  // eslint-disable-next-line no-unused-vars
  const getRoutes = () => {
    return window.location.pathname !== '/login';
  };

  return (
    <BrowserRouter>
      <EnhancedComponents.WebsiteNav/>
        <ScrollToTop/>
        <Switch>
          <Route exact path={'/home'} component={Homepage}/>
          <Route exact path={'/registration'} component={Registration}/>
          <Route exact path={'/why-join'} component={WhyJoin}/>
          <Route exact path={'/membership-info'} component={MembershipInfo}/>
          <Route exact path={'/about-us'} component={AboutUs}/>
          <Route exact path={'/contact-us'} component={ContactUs}/>
          <Route exact path={'/our-story'} component={OurStory}/>
          <Route exact path={'/dashboard'} component={Dashboard}/>
          <Route exact path={'/lectures'} component={Lectures}/>
          <Route exact path={'/common-rooms'} component={CommonRooms}/>
          <Route exact path={'/attorneys'} component={Attorneys}/>
          <Route exact path={'/service-providers'} component={ServiceProviders}/>
          <Route exact path={'/juniors'} component={Juniors}/>
          <Route exact path={'/skills-vault'} component={SkillsVault}/>
          <Route exact path={'/profile/:userId'} component={ProfilePage}/>
          <Route exact path={'/members'} component={Members}/>
          <Route exact path={'/profile'} component={Profile}/>
          <Route exact path={'/court-rolls'} component={Courtrolls}/>
          <Route exact path={'/court-links'} component={Courtlinks}/>
          <Route exact path={'/court-roll/:id'} component={Courtroll}/>
          <Route exact path={'/tos'} component={Tos}/>
          <Route exact path={'/privacy'} component={Privacy}/>
          <Route exact path={'/cookies'} component={CookiePolicy}/>
          <Route exact path={'/blog/:id'} component={Blog}/>
          <Route exact path={'/blog-archive'} component={BlogArchive}/>
          <Route exact path={'/reset-password'} component={ResetPassword}/>
          <Route exact path={'/knock-knock'} component={Knockknock}/>
          <Route exact path={'/knock/:id'} component={Knock}/>
          <Route exact path={'/typists'} component={Typists}/>
          <Route exact path={'/popia'} component={Popia}/>
          <Route exact path={'*'} component={Homepage}/>
        </Switch>
        <div style={{paddingBottom: 50}}></div>
      <EnhancedComponents.WebsiteFooter/>
    </BrowserRouter>
  );
};

export default Routes;
