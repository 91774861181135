import React, { useState, useEffect } from "react";
import BasicComponents from "../../basicComponents/index";
import * as Yup from "yup";
import { Formik, Form, FormikProps, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import { Button, TextField } from "@material-ui/core";
import endPoints from "../../../services/api/root/endPoints";
import { globalLocalStorage } from "../../../services/storage/localStorage";
import MultiSelect from "react-multi-select-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface UserObject {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
}

export const SignUpStepTwo = (Props: any) => {
  const [errorMessage, setErrorMessage] = useState("An Error has occurred.");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [vcEmailInputVisible, setVcEmailInputVisible] = useState(false);
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [type, setType] = useState("text");

  function onFocus() {
    setType("date");
  }

  useEffect(() => {
    endPoints.getSkills().then((res: any) => {
      if (res.success) {
        setOptions(
          res.message.map((item: any) => {
            return { ...item, value: item.id };
          })
        );
      }
    });
  }, []);

  return (
    <Formik
      initialValues={{
        identityNumber: "",
        bio: "",
        advocateDateOfAdmission: "",
        legalPracticeNumber: "",
        virtualChambersEmail: false,
        preferredVcEmail: null,
      }}
      validationSchema={Yup.object().shape({
        identityNumber: Yup.string().required("Identity Number is required"),
        bio: Yup.string().required("Bio is required"),
        advocateDateOfAdmission: Yup.string().required(
          "Date of admission is required"
        ),
        legalPracticeNumber: Yup.string().required(
          "Legal practice number is required"
        ),
      })}
      onSubmit={(fields) => {
        if (!selected.length) return;
        fields.advocateDateOfAdmission = fields.advocateDateOfAdmission.replace(
          /\//g,
          "-"
        );
        endPoints
          .updateProfile({ ...fields, skills: selected })
          .then((res: any) => {
            if (res.success) {
              globalLocalStorage.storeItem("user", JSON.stringify(res.message));
              toast.success("Successfully updated your information", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
              });
              Props.openNextModal();
            } else {
              toast.error("Unable to update your information", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
              });
            }
          });
      }}
    >
      {(formik: any) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isValid,
          dirty,
        } = formik;
        return (
          <div className={'signUpStepTwo'}>
            <h2 className="primary light tcenter one">Step 2</h2>
            <Form style={{width: '100%'}}>
              <div className="one">
                <h4 className="primary light">
                  Please continue with the registration process below
                </h4>
                <input
                  placeholder="Identity number"
                  name="identityNumber"
                  type="text"
                  id="identityNumber"
                  value={values.identityNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="defaultInput"
                />
                <ErrorMessage name="identityNumber">
                  {(msg) => <div className="error">{msg}</div>}
                </ErrorMessage>
                <input
                  placeholder="Legal practice number"
                  name="legalPracticeNumber"
                  type="text"
                  id="legalPracticeNumber"
                  value={values.legalPracticeNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="defaultInput"
                />
                <ErrorMessage name="legalPracticeNumber">
                  {(msg) => <div className="error">{msg}</div>}
                </ErrorMessage>
                <input
                  placeholder="Date of admission as advocate"
                  name="advocateDateOfAdmission"
                  type={type}
                  id="advocateDateOfAdmission"
                  autoComplete={"false"}
                  onFocus={onFocus}
                  value={values.advocateDateOfAdmission}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="defaultInput"
                />
                <ErrorMessage name="advocateDateOfAdmission">
                  {(msg) => <div className="error">{msg}</div>}
                </ErrorMessage>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  labelledBy="Select skills"
                  overrideStrings={{ selectSomeItems: "Select your skills" }}
                  className="multi_select"
                />
                {/*<input 
                        placeholder = 'Small professional bio'
                        name = 'bio'
                        type = 'text'
                        id= 'bio'
                        value={values.bio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className = 'defaultInput'
                        />*/}
                <textarea
                  id="bio"
                  placeholder="Small professional bio"
                  value={values.bio}
                  onChange={handleChange}
                  className="textarea"
                  name="bio"
                  rows={6}
                  cols={50}
                />
                <ErrorMessage name="bio">
                  {(msg) => <div className="error">{msg}</div>}
                </ErrorMessage>
                <p
                  className="acceptTos"
                  style={{ margin: "0", display: "flex", alignItems: "center" }}
                >
                  <input
                    id="virtualChambersEmail"
                    name="virtualChambersEmail"
                    value={values.virtualChambersEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="checkbox"
                  />
                  Would you like to request an @virtualchambers.co.za address as
                  part of your membership fee?
                </p>
                {values.virtualChambersEmail ? (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <input
                      name={"preferredVcEmail"}
                      onChange={handleChange}
                      placeholder={"Preferred email address"}
                      className={"defaultInput m-0"}
                      style={{ width: "100%" }}
                    ></input>
                    <p
                      style={{
                        fontSize: 14,
                        color: "grey",
                        margin: 0,
                        height: "100%",
                        backgroundColor: "lightgrey",
                        padding: "0 10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      @virtualchambers.co.za
                    </p>
                  </div>
                ) : null}
                <BasicComponents.SecondaryButton
                  onClick={null}
                  label="Next Step"
                  type="submit"
                />
                <div style={{ height: "15px" }}></div>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};
