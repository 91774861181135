import React, { useState } from 'react';
import { Link } from 'react-router-dom';

interface props{
    label: string;
    users: any[];
}

export const SkillsListDropdown = (Props: props) =>{

    const  [dropdown, setDropdown] = useState(false)

    const toggleDropdown = () => {

        setDropdown(!dropdown)
    }


  return(
    <div onClick= {toggleDropdown} id = 'skillsListDropdown' className = 'skillsListDropdown flexRow spaceBetween'>
        <p className = 'label primary medium primary'>{Props.label}</p>
        <img id = 'chevron' className = {dropdown ? 'chevron rotated' : 'chevron' } src = {require('../../../assets/images/chevronBlue.png')}/>
        {
            dropdown ? 
                <div className = 'skillsList'>
                    <ul className = 'list noMargin primary'>
                        {Props.users.map((item:any, index) => {
                            return <Link to={`/profile/${item.id}`} style={{textDecoration: 'none'}}><li className = 'listItem primary' key={index}>{item.name} {item.surname}</li></Link>
                        })}
                    </ul>
                </div>
            :
            null
        }
    </div>
  )
}
