import React from 'react';

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';

import './styles.scss'

const MembershipInfo = () =>{
  return (
    <div style={{paddingBottom: 20}}>
      <div className = 'p-50-20-10 top whyJoin'>
        <h1 className = 'line primary'>Why Join</h1>
          <div className = 'whyJoinGrid one'>
              <div className = 'content two'>
                <div className = 'contentLeft'>
                    <h2 className = 'content primary light' style={{direction: "ltr", margin: 0}}>
                      Be part of <span className='bold '>TOMORROW’S ADVOCATES TODAY</span></h2><p className='primary'>
                      <br/>
                      Join the ranks of <span className='medium '>South Africa's forward-thinking Advocates</span> and be part of an illustrious group
                      <br/>
                      <br/>
                      Virtual Chambers welcomes all members, irrespective of whether you are part of 
                      any other legal association or group, as long as you are LPC compliant.
                      Practise from anywhere, anytime, with your Chambers at your fingertips, 
                      available on desktop and mobile
                    </p>
                    <br/>
                    <br/>
                    <div className='item'>
                    <div className ='two acenter'>
                      <img className="whyJoinIcon" src={require("../../../assets/images/connected-01.png")}/>
                        <h1 className = 'content primary  ' style={{direction: "ltr"}}>
                          Stay Connected
                        </h1>
                        </div>
                        <p className = 'content primary light'>
                          Connect with colleagues and enjoy all the benefits of physical chambers without the cost or geographical limitations
                        </p>
                        </div>

                        <div className='item'>
                    <div className ='two'>
                      <img className="whyJoinIcon" src={require("../../../assets/images/lineChart.png")}/>
                        <h1 className = 'content primary  ' style={{direction: "ltr"}}>
                          Skills Development & Group Training
                        </h1>
                        </div>
                        <p className = 'content primary light'>
                          Members will enjoy sharing intellectual capital from lectures by Judges, industry leaders and authors, on interactive platforms, in lecture format, chat forums and blogs
                        </p>
                    </div>

                    <div className='item'>
                    <div className ='two'>
                      <img className="whyJoinIcon" src={require("../../../assets/images/overFlow.png")}/>
                        <h1 className = 'content primary ' style={{direction: "ltr"}}>
                          Flow-Over Work
                        </h1>
                    </div>
                        <p className = 'content primary light'>
                          Share and receive flow-over work from our members on a national virtual platform
                        </p>
                    </div>
                    <div className='item'>
                    <div className ='two'>
                      <img className="whyJoinIcon" src={require("../../../assets/images/costBlue-01.png")}/>
                        <h1 className = 'content primary ' style={{direction: "ltr"}}>
                          Cost-Effective
                        </h1>
                      </div>
                        <p className = 'content primary light'>
                          Office rentals and shared overhead costs for staff, utilities and other levies/fees are a thing of the past.
                          Pay only your monthly membership fees to enjoy participation in the complete Advocates 
                          Chambers experience
                        </p>
                        </div>

                </div>
                <div className = 'contentRight'>
                    <img className = 'contentImage' src = {require('../../../assets/images/membersImage.png')}/>
                </div> 
              </div>
              <div className = 'content two'>
                <div className = 'contentLeft'>
                    <div className= 'acenter flexCol'>
                      <h1 className = 'content primary' style={{direction: "ltr"}}>
                        What you get 
                      </h1>
                      <ul style={{direction: "ltr"}}>
                        <li className ='content primary light'>The real Advocates Chambers experienced Virtually</li>
                        <li className ='content primary light' style={{direction: "ltr"}}>Stay connected, interact, chat, share knowledge and insight with your colleagues</li>
                        <li className ='content primary light'>Geographically unlimited Chambers at your fingertips</li>
                        <li className ='content primary light'>Referrals and collaboration with your colleagues</li>
                        <li className ='content primary light'>Participate and collaborate on our Virtual Q&A platform through "Knock-Knock"</li>
                        <li className ='content primary light'>Skills development, group training and lectures with a “request a topic” facility</li>
                        <li className ='content primary light'>Virtual common rooms with morning coffee, lunch breaks, afternoon tea and “The Pub” on Fridays</li>
                        <li className ='content primary light'>Stay current with our news blogs</li>
                        <li className ='content primary light'>Upload your professional biography on the home page</li>
                        <li className ='content primary light'>Present your field of expertise in our Skills Vault</li>
                        <li className ='content primary light'>Be part of uplifting our 0-5 juniors through the "Knock-Knock" platform, referrals and training</li>
                        <li className ='content primary light'>Court links to access daily rolls in all divisions</li>
                        <li className ='content primary light'>Get live feeds from Constitutional Court and SCA hearings for members only</li>
                        <li className ='content primary light'>Source from our freelance typist pool</li>
                        <li className ='content primary light'>Acquire external technical support services online from PC2PC</li>
                      </ul>
                    </div>
                    <br/>
                    <br/>
                    <h1 className = 'content primary' style={{direction: "ltr"}}>
                      What You Pay
                    </h1>
                    <p className = 'content primary' style={{direction: "ltr"}}>R2 500 PER MONTH *see terms and conditions*</p>
                    <p className = 'content primary' style={{direction: "ltr"}}>Your own Virtual Chambers email address - name@virtualchambers.co.za</p>
                    <br/>
                    <br/>
                    <h1 className = 'content primary' style={{direction: "ltr"}}>
                      What You Need
                    </h1>
                    <p className = 'content primary' style={{direction: "ltr"}}>Valid LPC membership number and paid-up membership</p>
                    <p className = 'content primary' style={{direction: "ltr"}}>Wi-Fi and Zoom</p>
                    <br/>
                    <br/>
                    <h1 className = 'content primary' style={{direction: "ltr"}}>
                      How To Become a Member Step-by-Step
                    </h1>
                    <div style={{direction: "ltr"}}>
                    <iframe
                        title="Step-by-Step"
                        src={`https://youtube.com/embed/xNXTvtxX6n8`}
                        allowFullScreen={true}
                        width="560"
                        height="315"
                        className='iframe'
                    />
                    </div>
                    <br/>
                    <br/>
                    <h1 className = 'content primary ' style={{direction: "ltr"}}>
                      Platform Tutorial
                    </h1>
                    <iframe
                        title="Tutorial"
                        src={`https://youtube.com/embed/J22lJJfmO8k`}
                        allowFullScreen={true}
                        width="560"
                        height="315"
                        className='iframe'
                    />
                    <br/>
                    <br/>
                </div>
                <div className = 'contentRight'>
                    <img className = 'contentImage' src = {require('../../../assets/images/skillsvaultImage.png')}/>
                </div> 
              </div>
          </div>
      </div>
    </div>
  )
}

export default MembershipInfo
