import React, { useEffect, useState } from 'react';
import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';
import endPoints from '../../../services/api/root/endPoints';
const Loader = require('react-loader');

const ServiceProviders = () =>{
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getServiceProviders = async () => {
    setLoading(true);
    const resp: any = await endPoints.getServiceProviders();
    if (resp.success) {
      setData(resp.message);
      setLoading(false);
      return;
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!loading && data.length === 0) {
      getServiceProviders();
    }
  }, [data])


  return (
    <div>
      <div className = 'top holder'>
        <h1 className = 'primary line'>Our Service Providers</h1>
        <div className = 'one grg20'>
            <div className = 'serviceProviders three'>
              {loading ? <Loader></Loader> : data.map((item: any, index: any) => {
                return <a href={item.link_url} target={'__blank'}><img className='providerLogo' src={`${process.env.REACT_APP_API_URL}${item.img.url}`} alt={item.img_alt_tag}></img></a> 
              })}
            </div>
      </div>
      </div>
    </div>
  )
}

export default ServiceProviders