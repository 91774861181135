import React, { useEffect, useState } from 'react';
import moment from "moment";
import endPoints from "../../../services/api/root/endPoints";
import BasicComponents from '../../basicComponents/index';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MultiSelect from "react-multi-select-component";



import { Link } from 'react-router-dom';


interface props{
  closeMeetingRequestModal: any;
}

export const UserMultiMeetingRequest = (Props: props) => {

    const [meetingTitle, setMeetingTitle] = useState("")
    const [meetingDesc, setMeetingDesc] = useState("")
    const [startDate, setStartDate] = useState<any>(new Date());
    const [success, setSuccess] = useState(false);
    const [buttonTxt, setButtonTxt] = useState("Request a meeting");
    const [selected, setSelected] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
      endPoints.getUsers().then((res:any) => {
        if (res.success) {
          setOptions(res.message.map((item:any) => {
            return {...item, value: item.id, label: `${item.name} ${item.surname}`}
          }))
        }
      })
    }, [])

    const filterPassedTime = (time:any) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
    
        return currentDate.getTime() < selectedDate.getTime();
    }

    function handleChange(e:any){
        setMeetingTitle(e.target.value)
      };
    
    
    function handleDescription(e:any){
        setMeetingDesc(e.target.value)
    };

    const createMeeting = async () => {

        // setButtonTxt("Please Wait...");
        // endPoints.meetings({
        //   "title": meetingTitle,
        //   "description": meetingDesc,
        //   "startTime": startDate,
        //   "members": [Props.userId]
        // }).then((res:any) => {
        //   if (res.success) {
        //       // alert("Meeting created successfuly")
        //       // Props.closeMeetingRequestModal();
        //       setSuccess(true);
        //   } else {
        //       toast.error("Failed to create a meeting", {
        //         position: "top-center",
        //         autoClose: 3000,
        //         hideProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: false,
        //         progress: undefined,
        //       });
        //   }
        //   setButtonTxt("Request a meeting");
        // })
    };

    /*useEffect(() => {
    }, [])*/

    return(
        <div>
         {!success ?
          <div  className="meetingRequestContainer">
              <h2 className = 'primary bold noMarginBottom'>Meeting Request</h2>

              <div className="meetingContentContainer">

              <div className="meetingFormContainer">
              <h4 className = 'primary light'>Request a time</h4>
              <DatePicker
              className= 'defaultInput'
              showTimeSelect
              selected={startDate}
              onChange={val => setStartDate(val)}
              dateFormat="d-MM-yyyy h:mm aa"
            />
            <input 
              placeholder="User Emails (Non Members)"
              className = 'defaultInput'
              />
              <MultiSelect
              options={options}
              value={selected}
              onChange={setSelected}
              labelledBy={"Select"}
              className = 'multi_select'
            />
              <input 
              placeholder="Request a Topic"
              value={meetingTitle}
                onChange={handleChange}
                                  className = 'defaultInput'
                                />           
                                <textarea
                                placeholder="Add Comments..."
                                onChange={handleDescription} className = 'defaultInput' rows={5}>
                                </textarea>
    
                                <BasicComponents.SecondaryButton
                                label = {buttonTxt}
                                type=""
                                onClick = {createMeeting}
                              />
              </div>

              </div>
            </div> :
            <div className="meetingRequestContainerSuccess">
              <h2 className = 'primary bold noMarginBottom'>Meeting Request</h2>
              <BasicComponents.SecondaryButton
                label = 'Finish'
                type=""
                onClick = {Props.closeMeetingRequestModal}
              />
            </div> }

        </div>
    )

}