import React, {useEffect, useState} from 'react';
import endPoints from "../../../services/api/root/endPoints";

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';
import { Link } from 'react-router-dom';
import moment from "moment";
import {globalLocalStorage} from "../../../services/storage/localStorage";
import Modal from 'react-modal';
import Select from 'react-select'
import Moment from 'react-moment';
import { useParams } from "react-router-dom";
import { classNames } from 'react-select/src/utils';
import ReactMde, { Suggestion, SaveImageHandler } from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {loginCheckService} from "../../../services/checkLogin";
const Loader = require('react-loader');

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
});

const customStylesOne = {
  content : {
    top                   : '60%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    minWidth              : '50vw',
    maxWidth              : '100vw',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

const Knock = () =>{

  const { id } = useParams();

  const [knock, setKnock] = useState<any>();
  const [profile, setProfile] = useState<any>();

  const [users, setUsers] = useState([])
  const [messages, setMessages] = useState([])
  const [selectedUsers, setSelectedUsersState] = useState([])
  const [modalOne, setModalOne] = useState(false)
  const [optionsState, setOptionsState] = useState("false");
  const [subscriber, setSubscriber] = useState<any>(null);

  const [value, setValue] = useState("");
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">(
    "write"
  );
  const [loaded, setLoaded] = useState<any>(true)

  const openModal = () => {
    setModalOne(true);
  }

  function closeModalOne(){
    setModalOne(false);
  }

  function setSelectedUsers(val:any) {
    const ids:any = [];

    for (let i = 0; i < val.length; i++) {
      ids.push(val[i].value);
    }
    setSelectedUsersState(ids);
  }

  const getKnock = async () => {
    setLoaded(false);
    endPoints.getUsers().then((res:any) => {
      if (res.success) {
        let allUsers = res.message;
        let filtered: any = res.message.filter((user:any) => {
          if (user.payfastpayments.length < 1 && (!user.influencer && !user.guest)) {
            return false;
          }
        return ((parseInt(moment().format('YYYY')) - parseInt(moment(user.advocateDateOfAdmission).format('YYYY'))) >= 5);
        })
        filtered = filtered.filter((user:any) => {
          return user.profileImage != null;
        })
        let options: any = [];
        for (let i = 0; i < filtered.length; i++) {
          let tmp = {
            value: filtered[i].id,
            label: `${filtered[i].name} ${filtered[i].surname}`
          }
          options.push(tmp)
        }
        setUsers(options);
        endPoints.knock(id).then((res: any) => {
          setLoaded(true);
          if (res.success) {
            // setBlog(res.message);
            let messagesTmp = res.message.knock_messages;

            let subscribersTmp = res.message.knock_knock_subscribers;

            let user:any = globalLocalStorage.getItem("user");
            user = user ? JSON.parse(user) : user;
            for (let i = 0; i < subscribersTmp.length; i++) {
              if (subscribersTmp[i].user == user.id) {
                setSubscriber(subscribersTmp[i]);
                setOptionsState("true");
                break ;
              }
            }
            const temp:any = [];

            temp.push({
              ...res.message,
              body: res.message.conversation,
              user: res.message.owner,
            })

            for (let i = 0; i < messagesTmp.length; i++) {
              for (let j = 0; j < allUsers.length; j++) {
                if (messagesTmp[i].user == allUsers[j].id) {
                  temp.push({
                    ...messagesTmp[i],
                    user: allUsers[j]
                  })
                }
              }
            }

            const viewsCount = parseInt(res.message.views) + 1;
            endPoints.updateKnockViews({
              views: viewsCount,
            }, id).then((res: any) => {});

            setKnock(res.message);
            setMessages(temp);
          }
        });
      }
    })
  }

  const createKnockKnock = async () => {
    if (value.length < 5) {
      toast.error("Your reply is too short", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      return ;
    }
    setLoaded(false);
    let user:any = globalLocalStorage.getItem("user");
    user = user ? JSON.parse(user) : user;
    let fields: any = {
      body: value,
      knock_knock: parseInt(id),
      user: profile?.id,
      posted_at: new Date(),
    }

    endPoints.createKnockComment(fields).then((res:any) => {
      setLoaded(true);
      if (res.success) {
        setValue("");
        toast.success("Message has been sent successfully", {
          position: "top-center",
          autoClose: 6000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        })
        getKnock();
      } else {
          toast.error("Unable to send a reply", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
      }
    })
  }

  useEffect(() => {

    loginCheckService.check();
    let user:any = globalLocalStorage.getItem("user");
    user = user ? JSON.parse(user) : user;
    if (user) {
      endPoints.userById(user.id).then((res:any) => {

        if (res.success) {
          setProfile(res.message);
        }
        })

        getKnock();


    } else
    window.location.href = '/home'
  }, [])

  function changedNotificationSettings (value:any) {
    if (value == "true") {
      let user:any = globalLocalStorage.getItem("user");
      user = user ? JSON.parse(user) : user;
      let fields: any = {
        knock_knocks: [parseInt(id)],
        user: profile?.id,
      }
      setLoaded(false);
      endPoints.createKnockSubscriber(fields).then((res:any) => {
        setLoaded(true);
        setSubscriber(res.message);
      })

    } if (value == "false" && subscriber) {
      setLoaded(false);
      endPoints.deleteKnockSubscriber(subscriber.id).then((res:any) => {
        setSubscriber(null);
        setLoaded(true);
      })
    }
    setOptionsState(value);
  }

  return (
    <div className = 'holdermembersList jc-c p-50-20-10'>
    <Loader loaded={loaded}>
      <div className = 'jc-sb' style={{paddingTop: '180px'}}>

      <div className='side-by-side-top'>

      <div className = 'select-container'>
      <Link to={'/knock-knock'}>
        <button className = 'white replyBtn'>New Knock-Knock</button>
      </Link>
      </div>


      <div className='select-container'>
        <select
        onChange={(event) => changedNotificationSettings(event.target.value)}
        value={optionsState}
        className = 'knock-dd' name="cars" id="cars">
          <option value="true" selected={optionsState == "true"}>Receive Notifications</option>
          <option value="false" selected={optionsState == "false"}>Do Not Receive Notifications</option>
        </select>
      </div>

      </div>

        <div className = 'side-by-side' style={{marginBottom: 30}}>

        <div className="start-section">
          <div className = 'profile'>    
            <img src = {`${process.env.REACT_APP_API_URL}${knock?.owner?.profileImage?.url}`}/>
          </div>
        </div>

          <div className = 'end-section'>
            <div className="top-header-title">
              <h2 className = 'primary first active no-pad'>{knock?.title} <span className="poster">by {knock?.owner?.name} {knock?.owner?.surname}</span></h2>
            </div>
            <div className="text-body">
                <ReactMarkdown plugins={[gfm]} children={ knock?.conversation }/>
            </div>
          </div>
        </div>
      </div>
          <div className = 'flexCol jc-c no-pad'>

          {messages.map((item:any, index:number) => {
            console.log('item', item);
            return (
            <div className= 'side-by-side comment-section jc-c'>

            <div className="start-section">
              <div className = 'profile '>    
                <img src = {`${item.user.profileImage && item.user.profileImage.url ? process.env.REACT_APP_API_URL : ''}${item.user.profileImage && item.user.profileImage.url ? item.user.profileImage.url : '/assets/image/userIcon.png'}`}/>
              </div>
            </div>

          <div className="end-section comment-box">
            <div className="comment-header">
              <div className="owner">{item.user.name} {item.user.surname}</div>
              <div className="blogDate"><Moment format="YYYY-MM-DD HH:mm">{item.user.posted_at}</Moment></div>
            </div>

            <div className="text-body">
              <ReactMarkdown plugins={[gfm]} children={ item.body }/>
            </div>
          </div>
          </div>
          )})}



          <div className= 'side-by-side comment-section jc-c'>

          <div className="start-section resp-hide">
            <div className = 'profile '>    
              <img src = {`${process.env.REACT_APP_API_URL}${profile?.profileImage?.url}`}/>
            </div>
          </div>

          <div className="end-section">

          <ReactMde
          value={value}
          onChange={setValue}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          generateMarkdownPreview={markdown =>
            Promise.resolve(converter.makeHtml(markdown))
          }
          childProps={{
            writeButton: {
              tabIndex: -1
            }
          }}
        />
          </div>

          </div>

          <div className="reply-button-section">
              <div className="reply-btn">
                <BasicComponents.DefaultBtn onClick={createKnockKnock} label = 'Reply'/>
              </div>
          </div>
          </div>
        </Loader>
    </div>
  )
}

export default Knock