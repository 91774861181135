import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import Moment from 'react-moment';
import gfm from "remark-gfm";
import { Link } from "react-router-dom";
import './styles.scss'

import endPoints from "../../../services/api/root/endPoints";

interface Blogs {
  image: { url: string };
  id: number;
  title: string;
  body: string;
  published_at: string;
}

const BlogArchive = () => {
  const [blogs, setBlogs] = useState<Blogs[]>([]);

  useEffect(() => {
    endPoints.getBlogs().then((res: any) => {
      if (res.success) {
        if (res.message.length) {
          setBlogs(res.message);
        }
      }
    });
  }, []);

  return (
    <div>
      <div className="blogArchive holder top">
        <h1 className="primary line">Blog Archive</h1>
        <div className="blogArchiveGrid one">
          {blogs ? (
            blogs.map((blog) => (
              <div className="content">
                <div className="contentLeft">
                <img
                    className="contentImage"
                    src={`${process.env.REACT_APP_API_URL}${blog.image.url}`}
                  />
                </div>
                <div className="contentRight">
                  
                  <h1 className="contentHeading primary medium" style={{marginBottom: '0px'}}>
                    {blog.title}
                  </h1>
                  <h6 className='blogDate'><Moment  format="YYYY/MM/DD" date={new Date(blog.published_at)} /></h6>
                 
                  <div className="paragraph primary">
                    {blog.body.length > 500 ? (
                      <ReactMarkdown
                        plugins={[gfm]}
                        children={`${blog.body.slice(0, 500)}`}
                      />
                    ) : (
                      <ReactMarkdown plugins={[gfm]} children={blog.body} />
                    )}
                    {blog.body.length > 500 ? (
                      <Link
                        to={`/blog/${blog.id}`}
                        className="secondary"
                        style={{ color: "#1F224A", cursor: "pointer" }}
                      >
                        Read More
                      </Link>
                    ) : null}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h1>Blog archive is empty</h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogArchive;
