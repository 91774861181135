import React, {useEffect, useState} from 'react';
import endPoints from "../../../services/api/root/endPoints";
import EnhancedComponents from '../../../components/enhancedComponents/index';
import { Link } from 'react-router-dom';
import moment from "moment";
import {globalLocalStorage} from "../../../services/storage/localStorage";
const Loader = require('react-loader');

const Members = () =>{

  const [loading, setLoading] = useState(false);
  const [userGroups, setUserGroups] = useState([])
  const [originalData, setOriginalData] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  function getFirstLetterFrom(value:string) {
    return value.slice(0, 1).toUpperCase();
  }

  function searchLogic(source:any, name:any) {
    var results = [];
    var index;
    var entry;

    name = name.toUpperCase();
    for (index = 0; index < source.length; ++index) {
        entry = source[index];
        if (entry && entry.name && (entry.name.toUpperCase().indexOf(name) !== -1 || entry.surname.toUpperCase().indexOf(name) !== -1)) {
            results.push(entry);
        }
    }

    return results;
  }

  function groupByYear(data: any) {
    const grouped = data
      .reduce(function (list:any, user:any) {
      let listItem = list.find((item:any) => item.year && item.year === moment(user.advocateDateOfAdmission).format('YYYY'));
      if (!listItem) {
        list.push({"year": moment(user.advocateDateOfAdmission).format('YYYY'), "users": [user]})
      } else {
        listItem.users.push(user)
      }
      return list;
      }, []);
    grouped.sort((a: any, b: any) => a.year - b.year);
    return grouped;
  }

  function groupAlphabetically(data: any) {
    const grouped = data
      .reduce(function (list:any, user:any) {
      let listItem = list.find((item:any) => item.letter && item.letter === getFirstLetterFrom(user.surname));
      if (!listItem) {
        list.push({"letter": getFirstLetterFrom(user.surname), "users": [user]})
      } else {
        listItem.users.push(user)
      }
      return list;
      }, []);
    grouped.sort((a: any, b: any) => (a.letter > b.letter) ? 1 : ((b.letter > a.letter) ? -1 : 0));
    return grouped;
  }

  function groupData(data:any) {
    let grouped;
    switch (currentTab) {
      case 0: case 1:
        grouped = groupByYear(data);
        break;
      case 2:
        grouped = groupAlphabetically(data);
        break;
    }
    grouped.forEach((group: any) => {
      group.users.sort((a: any, b: any) => (a.surname > b.surname) ? 1 : ((b.surname > a.surname) ? -1 : 0));
    });
    setUserGroups(grouped);
  }

  const search = (event:any) => {
    const query:any = event.target.value;
    if (!query.length)
      return groupData(originalData);
    groupData(searchLogic(originalData, query));
  };

  useEffect(() => {

    let user:any = globalLocalStorage.getItem("user");
    // if (!user)
      /* window.location.href = '/home' */
    if (!loading) {
      setLoading(true);
      endPoints.getUsers().then((res:any) => {
        if (res.success) {
          let filtered: any = res.message.filter((user:any) => {
            if (user.payfastpayments.length < 1 && (!user.influencer && !user.guest)) {
              return false;
            }
            switch (currentTab) {
              case 0: case 2:
                return true;
              case 1:
                return ((parseInt(moment().format('YYYY')) - parseInt(moment(user.advocateDateOfAdmission).format('YYYY'))) < 5);
            }
          })
          filtered = filtered.filter((user:any) => {
            return user.profileImage != null;
          })
          setOriginalData(filtered);
          groupData(filtered);
          setLoading(false);
        }
      })
    }
      
      
    // else {
      
    // }
  }, [currentTab])

  return (
    <div className = 'membersList'>
      <div className = 'top holder'>
        <div className = 'one'>
          <div className = 'flexRow jc-fs-sb'>
            <h1 onClick={() => setCurrentTab(0)} style={{cursor: 'pointer'}} className={`primary bold first ${currentTab === 0 ? 'active' : ''}`}>By Seniority</h1>
            <h1 onClick={() => setCurrentTab(1)} style={{cursor: 'pointer'}} className={`primary bold first ${currentTab === 1 ? 'active' : ''}`}>0-5 Juniors</h1>
            <h1 onClick={() => setCurrentTab(2)} style={{cursor: 'pointer'}} className={`primary bold first ${currentTab === 2 ? 'active' : ''}`}>Alphabetically</h1>
            {/* <Link style={{textDecoration: 'none'}} to={'/juniors'}><h1 className = 'primary bold' >Our Juniors</h1></Link> */}
          </div>
        </div>
      </div>
          <div className = 'membersGrid'>
            <div className = 'holder noPadding'>
              <div className = 'inputGroup'><span className="innericon searchIconBlue"/><input placeholder = 'Search' className = 'defaultSearch' onChange={search} type='text'/></div>
              </div>
              {loading ? <div style={{marginTop: '60px'}} className='holder noPadding'><Loader></Loader>-</div> : userGroups.map((group:any, index:number) => {
                return (
              [<div className = 'holder noPadding' key={group.letter || group.year}>
                <h2 className = 'primary'>{group.letter || group.year}</h2>
              </div>,
              <div className = 'six gcg10 grg10' key={index}>
              {group.users.map((item:any, index:number) => {
                return (
                  <Link to={`/profile/${item.id}`}  key={index}>
                  <EnhancedComponents.MembersCard name = {item.name} surname={item.surname} number = {item.phone} email = {item.email} user={item}/>
                  </Link>
                  )
              })}
              </div>]
              )})}
              
          </div>
    </div>
  )
}

export default Members