import React, {useEffect, useState} from 'react';
import endPoints from "../../../services/api/root/endPoints";

const Typists = () =>{

  const [data, setData] = useState([]);
  const [sortedData, setSortedData] = useState([])
  const [loading, setLoading] = useState(false);

  const getTypists = async () => {
      setLoading(true);
      const response: any = await endPoints.getTypists();
      if (response.success) {
        setData(response.message.sort((a: any, b: any) => a.Surname.localeCompare(b.Surname)));
        setLoading(false);
      }
  }


  useEffect(() => {
      if (!loading && data.length === 0) {
        getTypists();
      }
  }, [data])

  return (
    <div>
      <div className="holder top">
        <div className="typists one">
          <h1 className="primary line">Typist Pool</h1>
            <div className="three gcg30">
              {data.map((item: any, index) => {
                return (
                  <div key={`typist-${index}`}>
                    <div className="card">
                      <h4 className="primary line">
                        {item.Name} {item.Surname}
                      </h4>
                      <div className='two center user'>
                        <img className='typistLogo' src={item.Profile_Image ? `${process.env.REACT_APP_API_URL}${item.Profile_Image.url}` : require('../../../assets/images/userIcon.png')}/>
                        <div>
                          <p className="primary light"><span className="semi-bold">Email Address: </span> {item.Email}</p>
                          <p className="primary light"><span className="semi-bold">Contact Number:</span> {item.Contact_Number}</p>
                          <p className="primary light"><span className="semi-bold">Rate Per Page: </span>R {item.Rate_Per_Page}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
      </div>
    </div>
  );
}

export default Typists;