import React, { useEffect, useState } from 'react';
import BasicComponents from '../../../components/basicComponents/index';
import endPoints from "../../../services/api/root/endPoints";


const SkillsVault = () =>{

  const [skills, setSkills] = useState([])

  function groupBy(arr: string | any[], n: number) {
    var group = [];
    for (var i = 0, end = arr.length / n; i < end; ++i)
      group.push(arr.slice(i * n, (i + 1) * n));
    return group;
  }

  useEffect(() => {
    endPoints.getSkills().then((res:any) => {
        if (res.success) {
          setSkills(res.message.sort((a: any, b: any) => { return a.label.localeCompare(b.label) }));
        }

   })
  }, [])

  return (
    <div>
      <div className = 'holder top'>
            <h1 className = 'primary line'>Our Skills Vault</h1>
            <div className = 'skillsVault one'> 
  {/*<div className = 'inputGroup'><span className="innericon searchIconBlue"/><input placeholder = 'Search' className = 'defaultSearch' type='text'/></div>*/}
                <div className = 'three skills list gcg30'>
                    {skills.sort((a: any, b: any) => a.label - b.label).map((item:any, i:number) => {
                      return <BasicComponents.SkillsListDropdown label = {item.label} users = {item.users} key={i}/>
                      })}
              
                </div>
            </div>
      </div>
    </div>
  )
}

export default SkillsVault