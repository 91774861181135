import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import Moment from 'react-moment';
import endPoints from "../../../services/api/root/endPoints";
import { useParams } from "react-router-dom";

const Blog = () => {
  const { id } = useParams();

  const [blog, setBlog] = useState({
    image: {
      url: "",
    },
    body: "",
    title: "",
    published_at: ""
  });

  useEffect(() => {
    endPoints.blog(id).then((res: any) => {
      if (res.success) {
        setBlog(res.message);
      }
    });
  }, []);

  return (
    <div className="top center">
      <div className="">
        <div className="holder">
          <h1 className="secondary">Blog</h1>

          {blog && (
            <div className="blogTop">
              <img
                className="blogThumbnailLarge"
                src={`${process.env.REACT_APP_API_URL}${blog.image.url}`}
              />
              <div className="topBlogContent marginTopBottom">
                <h4 className="secondary headingOne uppercase ">
                  {blog.title}
                </h4>
                <h6 className='blogDate'><Moment  format="YYYY/MM/DD" date={new Date(blog.published_at)} /></h6>
                <div className="secondary blogContent paragraph marginTopBottom">
                  {blog.body.split("\n\n").map((paragraph) => (
                    <>
                      <ReactMarkdown plugins={[gfm]} children={paragraph} />
                      <br />
                    </>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Blog;
