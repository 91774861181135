import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import endPoints from "../../../services/api/root/endPoints";
import Modal from "react-modal";

import EnhancedComponents from "../../../components/enhancedComponents/index";
import BasicComponents from "../../../components/basicComponents/index";
import moment from "moment";
import { globalLocalStorage } from "../../../services/storage/localStorage";

const customStyles = {
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const ProfilePage = () => {
  const [user, setUser] = useState({
    id: 0,
    bio: "",
    name: "",
    surname: "",
    email: "",
    phone: "",
    advocateDateOfAdmission: "",
    legalPracticeNumber: "",
    skills: [],
    profileImage: {
      url: "",
    },
    location: "",
  });

  const [meetingRequestModal, setMeetingRequestModal] = useState(false);

  const { userId } = useParams();

  function closeMeetingRequestModal() {
    setMeetingRequestModal(false);
  }

  function openMeetingRequestModal() {
    setMeetingRequestModal(true);
  }

  useEffect(() => {
    let user: any = globalLocalStorage.getItem("user");
    endPoints.userById(userId).then((res: any) => {
      if (res.success) {
        setUser(res.message);
      }
    });
  }, []);

  return (
    <div>
      {user ? (
        <div className="holder top" style={{ width: "100%" }}>
          <div className="profilePage one grg30 one-resp">
            <div className="top two">
              <div className="personalInformation ">
                <img
                  className="profileImage"
                  src={`${process.env.REACT_APP_API_URL}${
                    user.profileImage ? user.profileImage.url : ""
                  }`}
                />
              </div>
              <div className="one grg20 one-resp">
                <div className="personalInformationContent gcg20 two">
                  <div className="personalInfo">
                    <h2 className="white bold noMarginBottom">
                      {user.name} {user.surname}
                    </h2>
                    <h3 className="white medium noMargin">Advocate</h3>
                    <div className="flexRow">
                      <img
                        className="icon"
                        src={require("../../../assets/images/envelope.png")}
                      />
                      <a
                        style={{ textDecoration: "none" }}
                        href={"mailto:" + user.email}
                        className="white light"
                      >
                        {user.email}
                      </a>
                    </div>
                    <div className="flexRow">
                      <img
                        className="icon"
                        src={require("../../../assets/images/phone-alt.png")}
                      />
                      <a
                        style={{ textDecoration: "none" }}
                        href={"tel:" + user.phone}
                        className="white light"
                      >
                        {user.phone}
                      </a>
                    </div>
                  </div>
                  <div className="right">
                    <div className="iconHeading flexRow underline">
                      <img
                        className="icon"
                        src={require("../../../assets/images/buisnessCardWhite.png")}
                      />
                      <h1 className="white noMargin">Bio</h1>
                    </div>
                    <p className="white light">{user.bio}</p>
                  </div>
                </div>
                <div className=" bottom two gcg20">
                  <div className="skillSet blueCard one grg10">
                    <div className="iconHeading flexRow">
                      <img
                        className="icon"
                        src={require("../../../assets/images/skillSetWhite.png")}
                      />
                      <h4 className="white noMargin">Skill Set</h4>
                    </div>
                    <div>
                      <ul className="noMargin skillList">
                        {user.skills.map((item: any, index) => {
                          return (
                            <li className="white light noMargin" key={index}>
                              {item.label}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>

                  <div className="infoRight one grg10">
                    <div className="blueCard">
                      <div className="iconHeading flexRow">
                        <img
                          className="icon"
                          src={require("../../../assets/images/locationWhite.png")}
                        />
                        <div>
                          <h4 className="white noMargin">
                            Geographic Location
                          </h4>
                          <p className="white light">
                            {user.location ? user.location : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="blueCard">
                      <div className="iconHeading flexRow">
                        <img
                          className="icon"
                          src={require("../../../assets/images/calanderWhite.png")}
                        />
                        <div>
                          <h4 className="white noMargin">
                            Date of Admission as Advocate
                          </h4>
                          <p className="white light">
                            {moment(user.advocateDateOfAdmission).format("DD")}{" "}
                            {moment(user.advocateDateOfAdmission).format(
                              "MMMM"
                            )}{" "}
                            {moment(user.advocateDateOfAdmission).format(
                              "YYYY"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="blueCard">
                      <div className="iconHeading flexRow">
                        <img
                          className="icon"
                          src={require("../../../assets/images/attorneysRoomWhite.png")}
                        />
                        <div>
                          <h4 className="white noMargin">
                            Legal Practice Counsel Number
                          </h4>
                          <p className="white light">
                            {user.legalPracticeNumber}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <Modal
        ariaHideApp={false}
        style={customStyles}
        isOpen={meetingRequestModal}
        onRequestClose={closeMeetingRequestModal}
      >
        <EnhancedComponents.UserMeetingRequest
          closeMeetingRequestModal={closeMeetingRequestModal}
          name={user.name}
          surname={user.surname}
          email={user.email}
          userId={user.id}
          photo={`${process.env.REACT_APP_API_URL}${
            user.profileImage ? user.profileImage.url : ""
          }`}
        />
      </Modal>
    </div>
  );
};

export default ProfilePage;
