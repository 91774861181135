import React, { useEffect, useState } from 'react';
import moment from "moment";
import endPoints from "../../../services/api/root/endPoints";
import BasicComponents from '../../../components/basicComponents/index';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { Link } from 'react-router-dom';

interface props{

    name: string;
    surname: string;
    email: string;
    photo: string;
    userId: number;
    closeMeetingRequestModal: any;
  
}

export const UserMeetingRequest = (Props: props) => {

    const [meetingTitle, setMeetingTitle] = useState("")
    const [meetingDesc, setMeetingDesc] = useState("")
    const [startDate, setStartDate] = useState<any>(new Date());
    const [success, setSuccess] = useState(false);
    const [buttonTxt, setButtonTxt] = useState("Request a meeting");

    const filterPassedTime = (time:any) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
    
        return currentDate.getTime() < selectedDate.getTime();
    }

    function handleChange(e:any){
        setMeetingTitle(e.target.value)
      };
    
    
    function handleDescription(e:any){
        setMeetingDesc(e.target.value)
    };

    const createMeeting = async () => {

        setButtonTxt("Please Wait...");
        endPoints.meetings({
          "title": meetingTitle,
          "description": meetingDesc,
          "startTime": startDate,
          "members": [Props.userId]
        }).then((res:any) => {
          if (res.success) {
              // alert("Meeting created successfuly")
              // Props.closeMeetingRequestModal();
              setSuccess(true);
          } else {
              toast.error("Failed to create a meeting", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
              });
          }
          setButtonTxt("Request a meeting");
        })
    };

    /*useEffect(() => {
    }, [])*/

    return(
        <div>
         {!success ?
          <div  className="meetingRequestContainer">
              <h2 className = 'primary bold noMarginBottom'>Meeting Request</h2>
              <p>with {Props.name} {Props.surname}</p>

              <div className="meetingContentContainer">
              <div className="ProfilePicContainer">
                  <img className = 'profilePic' src = {Props.photo}/>
              </div>

              <div className="meetingFormContainer">
              <h4 className = 'primary light'>Request a time</h4>
              <DatePicker
              className= 'defaultInput'
              showTimeSelect
              selected={startDate}
              onChange={val => setStartDate(val)}
              dateFormat="d-MM-yyyy h:mm aa"
            />
              <input 
              placeholder="Request a Topic"
              value={meetingTitle}
                onChange={handleChange}
                                  className = 'defaultInput'
                                />           
                                <textarea
                                placeholder={`Add Extra Comments for ${Props.name} ${Props.surname}`}
                                onChange={handleDescription} className = 'defaultInput' id="w3review" name="w3review" rows={5}>
                                {meetingDesc}
                                </textarea>
    
                                <BasicComponents.SecondaryButton
                                label = {buttonTxt}
                                type=""
                                onClick = {createMeeting}
                              />
              </div>

              </div>
            </div> :
            <div className="meetingRequestContainerSuccess">
              <h2 className = 'primary bold noMarginBottom'>Meeting Request</h2>
              <p>Successfully sent to {Props.name} {Props.surname}</p>
              <div className="ProfilePicContainer">
                <img className = 'profilePic' src = {Props.photo}/>
              </div>
              <BasicComponents.SecondaryButton
                label = 'Finish'
                type=""
                onClick = {Props.closeMeetingRequestModal}
              />
            </div> }

        </div>
    )

}