import React, {useEffect, useState} from 'react';

import { Link } from 'react-router-dom';

import BasicComponents from '../../basicComponents/index'
import endPoints from '../../../services/api/root/endPoints';


interface props{
  
  title: string;
  description: string;
  join_url: string;
  image: string;

}


export const CommonRoomsCard = (Props: props) =>{

  const [users, setUsers] = useState<any>([]);
  const [int, setInt] = useState(false);
  const url = new URL(Props.join_url);

  const getMeetingParticipants = () => {
    const roomId = url.pathname.split('/')[2];
    endPoints.getSingleMeetingParticipants(roomId).then((res:any) => {
      if(res.success && res.message) {
        let filtered = JSON.parse(res.message.participants);
        setInt(true);
        setUsers(filtered);
      }
    })
  }

  useEffect(() => {
    if (!int) {
      getMeetingParticipants();
      setInterval(getMeetingParticipants, 10000);
    }
  }, [users]);

  return(

    <div className = 'commonRoomCard card one'>
        <div className = 'top two'>
          <div className = 'left'>
            <h4 className = 'primary line roomHeading'>{Props.title}</h4>
            <p className = 'roomDescription primary light'>{Props.description}</p>
          </div>
          <img className = 'roomIcon' src = {`${process.env.REACT_APP_API_URL}${Props.image}`}/>
        </div>
        <div className = 'flexRow spaceBetween'>
          <p className = 'primary medium'>Who's In?</p>
          <button className = 'joinBtn'><a href={Props.join_url} target="_blank">Join</a></button>
        </div>
        <div className = 'users two'>
        {users.map((user:any, index:number) => {
          return (
          <BasicComponents.CommonRoomUser name = {`${user.user_name}`} key={index}/>)
        })}
        {!users.length && <p>There are no users in this room</p> }
  </div>
    </div>
  )
}