import React, { useEffect, useState } from "react";

import EnhancedComponents from "../../../components/enhancedComponents/index";
import BasicComponents from "../../../components/basicComponents/index";
import { DefaultBtn } from "../../../components/basicComponents/defaultBtn/defaultBtn";
import { globalLocalStorage } from "../../../services/storage/localStorage";
import { useAuthenticationProvider } from "../../../context/authenticationProvider";
import endPoints from "../../../services/api/root/endPoints";
import { loginCheckService } from "../../../services/checkLogin";

const Dashboard = () => {
  const [feed, setFeeds] = useState<null | Array<{
    Name: string;
    Link: string;
  }>>(null);
  const { auth, authSet } = useAuthenticationProvider();
  let user: any = null;

  useEffect(() => {
    if (!feed) {
      endPoints.getYoutubeFeeds().then((resp: any) => {
        setFeeds(resp.message);
      });
    }
  });

  useEffect(() => {
    loginCheckService.check();
    let token = globalLocalStorage.getItem("token");
    user = globalLocalStorage.getItem("user");
    if (!user) window.location.href = "/home";
    user = user ? JSON.parse(user) : user;
    if (token) {
      authSet(true);
    } else authSet(false);
  }, []);

  return (
    <div>
      <div className="db-container">
        <div className="dashboard two">
          <EnhancedComponents.NoticeBoard />
          <EnhancedComponents.DashboardCommonRoom
            feed={feed && feed[0] ? feed[0] : { Name: "", Link: "" }}
          />
          <EnhancedComponents.DashboardLecturesTable />
          <EnhancedComponents.DashboardCommonRoom
            feed={feed && feed[1] ? feed[1] : { Name: "", Link: "" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
