import React, { useState, useEffect } from "react";

import EnhancedComponents from "../../../components/enhancedComponents/index";
import BasicComponents from "../../../components/basicComponents/index";
import { globalLocalStorage } from "../../../services/storage/localStorage";
import * as Yup from "yup";
import { Formik, Form, FormikProps } from "formik";
import endPoints from "../../../services/api/root/endPoints";
import ImageUploader from "react-images-upload";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
import { useModalProvider } from "../../../context/modalProvider";
import MultiSelect from "react-multi-select-component";
import {loginCheckService} from "../../../services/checkLogin";

const Loader = require("react-loader");

interface UserObject {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
}

const Profile = () => {
  const [profile, setProfile] = useState<any>({});
  const [loaded, setLoaded] = useState<any>(false);
  const { active, toggleModal } = useModalProvider();
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [payfastToken, setPayfastToken] = useState("");

  useEffect(() => {
    loginCheckService.check();
    let user = globalLocalStorage.getItem("user");
    if (!user) window.location.href = "/home";
    endPoints.getSkills().then((res:any) => {
      if (res.success) {
        setOptions(res.message.map((item:any) => {
          return {...item, value: item.id}
        }))
      }
    })
    endPoints.getPayfastInfo().then((res:any) => {
      if(res.success) {
          // setPayfast(true);
          setPayfastToken(res.message.token);
      }
  });
    endPoints.me().then((res: any) => {
      if (res.success) {
        endPoints.userById(res.message.id).then((userProfile: any) => {
          if (userProfile.success) {
            setProfile(userProfile.message);
            setSelected(userProfile.message.skills)
            setLoaded(true);
          }
        });
      }
    });
  }, []);

  const onDrop = (pic: any) => {
    if (pic) {
      setLoaded(false);
      endPoints.uploadProfilePicture(pic).then((res: any) => {
        if (res.success) {
          setLoaded(true);
          toast.success("Your profile picture was updated successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          window.location.href = "/profile";
        }
      });
    }
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "45%",
      padding: "30px",
      height: "20%",
    },
  };

  return (
    <div>
      <Modal isOpen={active} style={customStyles}>
        <EnhancedComponents.ConfirmationModal
          heading="Update Payment"
          content="You are about to be redirected to a secure debit order portal. Would you like to proceed?"
          url={`https://payfast.co.za/eng/recurring/update/${payfastToken}?testing=true&return=https://virtualchambers.co.za`}
        />
      </Modal>
      <Loader loaded={loaded}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: profile.name,
            surname: profile.surname,
            email: profile.email,
            mobile: profile.phone,
            identityNumber: profile.identityNumber,
            bio: profile.bio,
            advocateDateOfAdmission: profile.advocateDateOfAdmission,
            legalPracticeNumber: profile.legalPracticeNumber,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("First Name is required"),
            surname: Yup.string().required("Last Name is required"),
            email: Yup.string()
              .email("Email is invalid")
              .required("Email is required"),
            identityNumber: Yup.string().required(
              "Identity Number is required"
            ),
            bio: Yup.string().required("Bio is required"),
            advocateDateOfAdmission: Yup.string().required(
              "Date of admission is required"
            ),
            legalPracticeNumber: Yup.string().required(
              "Legal practice number is required"
            ),
          })}
          onSubmit={(fields, { resetForm }) => {
            setLoaded(false);
            endPoints.updateProfile({
              ...fields,
              skills: selected, 
            }).then((res: any) => {
              if (res.success) {
                setLoaded(true);
                toast.success("Thank you. Your profile has been updated.", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  progress: undefined,
                });
              }
            });
          }}
        >
          {(formik: any) => {
            const {
              values,
              handleChange,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              isValid,
              dirty,
            } = formik;
            return (
              <div className="profilePageContainer">
                <div className="profilePage one grg30">
                  <h2 className="secondary medium tcenter">Update Profile</h2>
                  {profile.profileImage ? (
                    <div className="profileImageContainer">
                      <img
                        className="profileImage"
                        src={`${process.env.REACT_APP_API_URL}${profile.profileImage.url}`}
                      />
                      <ImageUploader
                        withIcon={false}
                        withPreview={false}
                        withLabel={false}
                        singleImage={true}
                        onChange={onDrop}
                        className="uploadContainer"
                        buttonClassName="uploadButton"
                        buttonText="Select New Profile Picture"
                        imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
                        maxFileSize={5242880}
                      />
                    </div>
                  ) : null}
                  <div className="centre">
                    <Form
                      style={{
                        display: "flex",
                        width: '90%',
                        flexDirection: "column",
                        alignSelf: "center",
                        margin: '0 5%',
                      }}
                    >
                      <label className="label">Name</label>
                      <input
                        placeholder="Name"
                        name="name"
                        type="text"
                        id="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="defaultInput"
                      />
                      {errors.surname}
                      <label className="label">Surname</label>
                      <input
                        placeholder="Surname"
                        name="surname"
                        type="text"
                        id="surname"
                        value={values.surname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="defaultInput"
                      />
                      {errors.last_name}
                      <label className="label">Email Address</label>
                      <input
                        placeholder="Email Address"
                        name="email"
                        type="text"
                        id="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="defaultInput"
                      />
                      {errors.email}
                      <label className="label">Mobile Number</label>
                      <input
                        placeholder="Mobile Number"
                        name="mobile"
                        type="text"
                        id="mobile"
                        value={values.mobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="defaultInput"
                      />
                      {errors.mobile}
                      <label className="label">Identity Number</label>
                      <input
                        placeholder="Identity Number"
                        name="identityNumber"
                        type="text"
                        id="identityNumber"
                        value={values.identityNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="defaultInput"
                      />
                      <label className="label">Legal Practice Number</label>
                      <input
                        placeholder="Legal practice number"
                        name="legalPracticeNumber"
                        type="text"
                        id="legalPracticeNumber"
                        value={values.legalPracticeNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="defaultInput"
                      />
                      <label className="label">Skills</label>
                      <MultiSelect
                        options={options}
                        value={selected}
                        onChange={setSelected}
                        labelledBy={"Select"}
                        className = 'multi_select'
                      />
                      <label className="label">
                        Date Of Admission As Advocate
                      </label>
                      <input
                        placeholder="Date of admission as advocate"
                        name="advocateDateOfAdmission"
                        type="date"
                        id="advocateDateOfAdmission"
                        value={values.advocateDateOfAdmission}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="defaultInput"
                      />
                      <label className="label">Small Professional Bio</label>
                      <input
                        placeholder="Small proffesional bio"
                        name="bio"
                        type="text"
                        id="bio"
                        value={values.bio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="defaultInput"
                      />
                      <div className="flexRow spaceBetween">
                        <BasicComponents.SecondaryButton
                          label="Update"
                          type="submit"
                          onClick={() => console.log("Form Submitted")}
                        />
                        <a style={{marginLeft: '10px', marginTop: '15px'}} className="updatePayment primary" onClick={() => toggleModal(true)}>
                          Update Debit Order Information
                        </a>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      </Loader>
    </div>
  );
};

export default Profile;
