import React, { useEffect, useState } from "react";
import endpoints from "../../../services/api/root/endPoints";

import EnhancedComponents from "../../../components/enhancedComponents/index";
import BasicComponents from "../../../components/basicComponents/index";

const Popia = () => {
  const [popiaDocs, setPopiaDocs] = useState([]);

  const getPopiaDocs = async () => {
    const popia = await endpoints.getPopiaDocs().then((res: any) => res);
    if (popia.success) {
      setPopiaDocs(popia.message.sort((a: any, b: any) => a.Name.localeCompare(b.Name)));
    }
  };

  useEffect(() => {
    if (!popiaDocs.length) {
      getPopiaDocs();
    }
  }, [popiaDocs]);

  return (
    <div className="top holder primary">
      <div className=" tcenter">
        <h1 className="line primary">
          POPIA Documentation
        </h1>
      </div>
      <h2 style={{ marginBottom: 0 }}>Download Links:</h2>
      <ul style={{ marginBottom: 50 }}>
        {popiaDocs.map((item: any, index) => {
          return (
            <li key={item.Name}>
              <a
                href={`${process.env.REACT_APP_API_URL}${item.Document.url}`}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none', color: '#2A4E60' }}
              >
                {item.Name}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Popia;
