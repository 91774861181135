import React, { useState } from "react";
import BasicComponents from "../../basicComponents/index";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import  EnhancedComponents  from '../../enhancedComponents/index'
import { Link } from "react-router-dom";
import { Button, TextField } from "@material-ui/core";
import { useAuthenticationProvider } from "../../../context/authenticationProvider";
import endPoints from "../../../services/api/root/endPoints";
import { globalLocalStorage } from "../../../services/storage/localStorage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from 'react-modal';
import { useModalProvider } from "../../../context/modalProvider";

const Loader = require("react-loader");

interface UserObject {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
  mobile: string;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
  },
};

export const SignUpStepOne = (Props: any) => {
  const [loginEmail, setLoginEmail] = React.useState("");
  const [loginPassword, setLoginPassword] = React.useState("");
  const [error, setError] = useState(null);
  const { auth, authSet } = useAuthenticationProvider();
  const [loaded, setLoaded] = useState<any>(true);
  const [loginError, setLoginError] = useState<any>(null);
  const [accountCreated, setAccountCreated] = useState<any>(false);
  const [forgotPassword, setForgotPassword] = useState<any>(false);
  const [modalHeading, setModalHeading] = useState<string>('');
  const [modalContent, setModalContent] = useState<string>('');
  const [showPass, setShowPass] = useState<boolean>(false);
  const {active, toggleModal} = useModalProvider();

  const phoneRegExp = /^\+?[0-9]\d{9,14}$/g;

  const handleSubmit = async (fields: UserObject) => {
    setLoaded(false);
    endPoints
      .register({
        username: fields.email,
        email: fields.email,
        name: fields.first_name,
        surname: fields.last_name,
        password: fields.password,
        phone: fields.mobile,
      })
      .then((res: any) => {
        setLoaded(true);
        if (res.success) {
          // Props.closeModalOne();
          setAccountCreated(true);
          // alert("Your account have been created please check your email");
          // toast.success("Your account have been created please check your email", {
          //   position: "top-center",
          //   autoClose: 3000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: false,
          //   progress: undefined,
          // });
        } else {
          setError(res.message.data.data[0].messages[0].message);
        }
      });
  };

  const login = async (fields: any) => {
    setLoaded(false);
    setLoginError(null);
    endPoints.login(fields).then((res: any) => {
      setLoaded(true);
      if (res.success) {
        if (res.message.user.confirmed) {
          setLoginError(null);
          globalLocalStorage.storeItem("user", JSON.stringify(res.message.user));
          globalLocalStorage.storeItem("token", JSON.stringify(res.message.jwt));
          Props.openNextModal();
          authSet(true);
        } else {
          setLoginError('This account is awaiting verification, please check your email');
        }
      } else {
        setLoginError("Unable to login");
      }
    });
  };

  const initForgotPassword = async (fields: any) => {
    setLoaded(false);
    endPoints.forgotPassword(fields).then((res:any) => {
      if(res.success == true){
      setModalHeading('Thank You');
      setModalContent('A verification email has been sent to your address. Please confirm to continue.')
      toggleModal(true);
      setLoaded(true);
    }
    else {
      setModalHeading('Error');
      setModalContent('We could not verify your account.')
      toggleModal(true);
      setLoaded(true);
    }
    })
  }

  return (
    <Loader loaded={loaded}>
      {!accountCreated ? (
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            email: "",
            mobile: "",
            password: "",
            confirm_password: "",
          }}
          validationSchema={Yup.object().shape({
            first_name: Yup.string().required("Name is required"),
            last_name: Yup.string().required("Surname is required"),
            email: Yup.string()
              .email("Email is invalid")
              .required("Email is required"),
            mobile: Yup.string()
              .matches(phoneRegExp, "Mobile number is not valid")
              .required("Mobile number is required"),
            password: Yup.string()
              .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
              )
              .required("Password is required"),
            confirm_password: Yup.string()
              .oneOf([Yup.ref("password"), ""], "Passwords must match")
              .required("Confirm Password is required"),
          })}
          onSubmit={handleSubmit}
        >
          {(formik: any) => {
            const {
              values,
              handleChange,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              isValid,
              dirty,
            } = formik;
            return (
              <div className="signUpModal">
                <Modal style={customStyles} isOpen={active}><EnhancedComponents.CustomModal heading= {modalHeading} content={modalContent}/></Modal>
                <span
                  onClick={Props.closeModalOne}
                  className="close primary"
                ></span>
                <h2 className="secondary medium tcenter mb-1">
                  Welcome to Virtual Chambers
                </h2>
                <div className="three signUpContent gcg30 resp-g-t-r">
                <div className="signUpTop one pt-1">
                    <img className='marginTopBottom signInLogo' src={require("../../../assets/images/logo.png")}></img>
                    <div>
                      <ul className='signUpProsList'>
                        <li>Chambers at your fingertips</li>
                        <li>Skills Development & Group Training</li>
                        <li>No shared overhead costs</li>
                        <li>Stay connected with your colleagues</li>
                      </ul>
                    </div>
                    <BasicComponents.SecondaryButton
                      label="Become a Member"
                      type="submit"
                      onClick={() => {
                        Props.closeModalOne();
                        window.location.href = "/registration";
                      }}
                    />
                  </div>
                  <div className="verticalBreak" />
                  { !forgotPassword ? 
                  <div className="signIn one pt-1">
                    <h4 className="secondary medium mt-0">Members Sign-In</h4>
                    {loginError && <div className="error">{loginError}</div>}
                    <input
                      className="defaultInput"
                      value={loginEmail}
                      onChange={(e) => setLoginEmail(e.target.value)}
                      placeholder="Enter your email address"
                    />
                    <div className='passwordInput'>
                      <input
                        type={showPass ? 'text' : 'password'}
                        value={loginPassword}
                        onChange={(e) => setLoginPassword(e.target.value)}
                        className="defaultInput"
                        placeholder="Enter your password"
                      ></input>
                      <i className='fa fa-eye showPassword' onClick={() => setShowPass(!showPass)}/>
                    </div>
                    <BasicComponents.SecondaryButton
                      onClick={() => {
                        login({
                          identifier: loginEmail,
                          password: loginPassword,
                        });
                      }}
                      type=""
                      label="Sign In"
                    />
                    <a onClick={() => setForgotPassword(true)} className="primary forgotPassword">Forgot Password</a>
                  </div>
                  : 
                  <div className="signIn one">
                    <h4 className="primary medium ">Forgot Password</h4>
                    <p>Did you forget your Virtual Chambers password?</p>
                    <p>To reset your password, please enter your email address below.</p>
                    <br/>
                    {loginError && <div className="error">{loginError}</div>}
                    <input
                      className="defaultInput"
                      value={loginEmail}
                      onChange={(e) => setLoginEmail(e.target.value)}
                      placeholder="Enter your email address"
                    />
                    <BasicComponents.SecondaryButton
                      onClick={() => {
                        initForgotPassword({
                          email: loginEmail
                        });
                      }}
                      type=""
                      label="Submit"
                    />
                    <a onClick={() => setForgotPassword(false)} className="primary forgotPassword">Cancel</a>
                  </div>
                }
                 
                </div>
              </div>
            );
          }}
        </Formik>
      ) : (
        <div className="signUpModal">
          <h2 className="secondary medium tcenter">Congratulations</h2>
          <p className="tcenter">
            Your account has been created successfully please check your email
            address to confirm your account.
          </p>
        </div>
      )}
    </Loader>
  );
};
