import React, { useEffect, useState } from 'react';
import Parser from 'rss-parser';
import endPoints from "../../../services/api/root/endPoints";

import { Link } from 'react-router-dom';

export const IndustryNews = () =>{

  let parser:any = new Parser();
  const [news, setNews] = useState([]);
  const [newsLeft, setNewsLeft] = useState([]);

  useEffect(() => {
    /*parser.parseURL('https://cors-anywhere.herokuapp.com/https://feeds.feedburner.com/JusticegovUpdates?format=xml').then((feed:any) => {
        if (feed.items) {
            let tmp:any = new Array(feed.items.shift())
            setNewsLeft(tmp)
            setNews(feed.items)
        }
    }).catch((err:any) => console.log("feed err", err))*/

    endPoints.getRssSecondary().then((rss:any) => {
        if (rss.success) {
          let data = rss.message;

          if (data.data) {
             let feed = data.data;
             let tmp:any = new Array(feed.items.shift())
             setNewsLeft(tmp)
             setNews(feed.items)

            }
     
          }
        })
    
  }, []);

  return(

    <div className = 'industryNews dashboardContainer'>
        <div className = 'top'>
            <h1 className = 'primary line noMarginBottom'>Industry News</h1>
        </div>
        <div className = 'two'>
        {newsLeft.map((item:any, index) => {
            return (
            <div className = 'left' key={index}>
                <h4 className = 'primary uppercase'>{item.title}</h4>
                <p className = 'light primary content'>{item.contentSnippet}</p>
                <a className = 'medium primary link' href={item.link} rel="noreferrer" target="_blank">Read Full Article</a>
            </div>)
        })}
        
            <div className = 'right'>
            {news.map((item:any, index) => {
                return (
                <div className = 'industryNewsItem' key={index}>
                    <p className = 'subHeading primary medium'> {item.title}</p>
                    <p className = 'light primary content'>{item.contentSnippet}</p>
                    <a className = 'medium primary link' href={item.link} rel="noreferrer" target="_blank">Read Full Article</a>
                </div>)
            })}

            </div>
        </div>
    </div>
  )
}