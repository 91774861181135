import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

export const WebsiteFooter = () => {
  return (
    <div className="footer">
      <div className="holder">
        <div className="one footerGrid">
          <div className="footerTop two">
            <div className="footerLinks">
              <h3 className="footerHeader white uppercase">Navigation</h3>
              <ul className="linkList">
                <li className="linkItem white uppercase">
                  <Link
                    style={{
                      fontWeight: 300,
                      textDecoration: "none",
                      color: "white",
                    }}
                    to={"/our-story"}
                  >
                    our story
                  </Link>
                </li>
                <li className="linkItem white uppercase">
                  <Link
                    style={{
                      fontWeight: 300,
                      textDecoration: "none",
                      color: "white",
                    }}
                    to={"/privacy"}
                  >
                    Privacy Policy
                  </Link>
                </li>

                <li className="linkItem white uppercase">
                  <Link
                    style={{
                      fontWeight: 300,
                      textDecoration: "none",
                      color: "white",
                    }}
                    to={"/cookies"}
                  >
                    Cookie Policy
                  </Link>
                </li>
                <li className="linkItem white uppercase">
                  <Link
                    style={{
                      fontWeight: 300,
                      textDecoration: "none",
                      color: "white",
                    }}
                    to={"/tos"}
                  >
                    Terms and conditions
                  </Link>
                </li>
                <li className="linkItem white uppercase">
                  <Link
                    style={{
                      fontWeight: 300,
                      textDecoration: "none",
                      color: "white",
                    }}
                    to={"/popia"}
                  >
                    POPIA
                  </Link>
                </li>
                {/* <li className = 'linkItem white uppercase'><Link style={{fontWeight:300,textDecoration:'none', color: 'white'}} to={'/blog-archive'}>Blog archives</Link></li> */}
                <li className="linkItem white uppercase">
                  <Link
                    style={{
                      fontWeight: 300,
                      textDecoration: "none",
                      color: "white",
                    }}
                    to={"/contact-us"}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footerContact two">
              <div>
                <h3 className="footerHeader white uppercase">contact us</h3>
                <ul className="contactList">
                  <li>
                    <a href="tel:0823753284" className="contactListItem white">
                      <img
                        className="footerContactIcon"
                        src={require("../../../assets/images/phone-alt.png")}
                      />
                      Platform Support and Enquiries - Rene: 0823753284
                    </a>
                  </li>
                  <li>
                    <a href="tel:0823753284" className="contactListItem white">
                      <img
                        className="footerContactIcon"
                        src={require("../../../assets/images/phone-alt.png")}
                      />
                      Finance and Administration - Bongiwe: 0662974579
                    </a>
                  </li>
                  <li>
                    <a href="tel:0823753284" className="contactListItem white">
                      <img
                        className="footerContactIcon"
                        src={require("../../../assets/images/phone-alt.png")}
                      />
                      Strategic Development - Ronelle: 0827700775
                    </a>
                  </li>
                  <li>
                    <a
                      className="contactListItem white"
                      href="mailto:admin@virtualchambers.co.za"
                    >
                      <img
                        className="footerContactIcon"
                        src={require("../../../assets/images/envelope.png")}
                      />
                      admin@virtualchambers.co.za
                    </a>
                  </li>
                </ul>
                {/*h3 className = 'footerHeader white uppercase'>Want us to contact you?</h3>*/}
                {/*<form>
                                <input className = 'footerInput' placeholder = 'Enter Your Email'/>
                            </form>*/}
              </div>
              <div className="right">
                <img
                  className="footerLogo"
                  src={require("../../../assets/images/logoWhite.png")}
                />
                <p className="white">
                  <span className="bold">POPIA Compliant</span>
                </p>
              </div>
            </div>
          </div>
          <div className="footerBottom">
            <div className="left">
              <p className="white">
                Copyright 2021 <span className="bold">Virtual Chambers</span>.{" "}
                <br />
                All rights reserved{" "}
              </p>
            </div>
            <div className="right flexRow">
              <a
                target="_blank"
                href="https://www.instagram.com/virtual_chambers/"
              >
                <img
                  className="footerSocial"
                  src={require("../../../assets/images/instagramWhite.png")}
                />
              </a>
              <a
                target="_blank"
                href="https://www.facebook.com/VirtualChambersSouthAfrica"
              >
                <img
                  className="footerSocial"
                  src={require("../../../assets/images/facebookWhite.png")}
                />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/in/virtual-chambers-tomorrow-s-advocates-today-277b04215"
              >
                <img
                  className="footerSocial"
                  src={require("../../../assets/images/linkedInWhite.png")}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
